import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import logger from 'redux-logger/src'
import { rootReducer } from './reducers/rootReducer'
import { composeWithDevTools } from 'redux-devtools-extension'

export const configureStore = () => {
  // console.log('Status: ', process.env.NODE_ENV)
  if (process.env.NODE_ENV === 'production') {
    return createStore(rootReducer, applyMiddleware(thunk))
  }

  return createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk, logger)))
}