import React from 'react'
import {useState, useEffect} from "react"
import {useHistory} from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import {useHttp} from "../../../hooks/http.hook"
import {Loader} from "../../partials/Loader"
import {useMessage} from "../../../hooks/message.hook"
import { logout } from '../../../redux/actions/auth.actions'

export const UserPasswordPage = () => {
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const [form, setForm] = useState({
    password: '',
    newPassword: '',
    retypePassword: ''
  })
  const {loading, request, error, clearError} = useHttp()
  const message = useMessage()
  const history = useHistory()

  useEffect(() => {
    message(error)
    if (error === 'Нет авторизации') {
      clearError()
      dispatch(logout())
      history.push('/')
    }
    clearError()
  }, [error, message, clearError, history, dispatch])

  const changeHandler = event => {
    setForm({ ...form, [event.target.name]: event.target.value })
  }

  const updateHandler = async () => {
    try {
      // check pass newpass = retypePass && length > 0
      if (!form.newPassword || form.newPassword !== form.retypePassword || form.newPassword.length < 6) {
        message(`"Новый пароль" и "Повторите пароль" должны совпадать!`)
        return
      }
      const data = await request('/api/auth/updatePassword', 'POST', {...form}, {authorization: 'Bearer ' + auth.token, id: auth.user.id})
      message(data.message)
    } catch (e) {}
  }

  if (!auth.user) {
    return <Loader />
  }

  return (
    <>
      <div className="row">
        <div className="col">
          <h5>Изменение пароля</h5>
          <p className="text-gray">Вы можете изменить свой пароль</p>
        </div>
      </div>

      <div className="row flex-column">
        <div className="col-4">
          <div className="input-group mt-4">
            <input
              placeholder="Текущий пароль"
              type="password"
              id="password"
              className="form-control"
              name="password"
              value={form.password}
              onChange={changeHandler}
            />
          </div>

          <div className="input-group mt-3">
            <input
              placeholder="Новый пароль"
              type="password"
              id="newPassword"
              className="form-control"
              name="newPassword"
              value={form.newPassword}
              onChange={changeHandler}
            />
          </div>

          <div className="input-group mt-3">
            <input
              placeholder="Повторите пароль"
              type="password"
              id="retypePassword"
              className="form-control"
              name="retypePassword"
              value={form.retypePassword}
              onChange={changeHandler}
            />
          </div>

          <button
            className="btn btn-bg-blue w-100 mt-3"
            onClick={updateHandler}
            disabled={loading}
          >
            Изменить пароль
          </button>

        </div>
      </div>
    </>
  )
}