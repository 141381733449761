import React from 'react'

export const MergeIcon = ({ width = 14, height = 18, mt = 0, mr = '1rem', color = '#D48806'  }) => {
  return (
    <div style={{marginTop: mt, marginRight: mr, width, height}}>
      <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.589844 16L1.99984 17.41L7.99984 11.41V5H11.4998L6.99984 0.5L2.49984 5H5.99984V10.59L0.589844 16ZM11.9968 17.41L8.58884 14.003L9.98884 12.596L13.3988 16.004L11.9968 17.41Z"
          fill={color}/>
      </svg>
    </div>
  )
}
