import React, { useCallback, useEffect, useState } from 'react'
import { useMessage } from '../../../hooks/message.hook'
import { useHttp } from '../../../hooks/http.hook'
import { Loader } from '../../partials/Loader'
import { NoTable } from '../../partials/NoTable'
import { Icon } from '../../partials/Icon'
import { useSelector } from 'react-redux'
import { BlockForm } from './BlockForm'


export const BlockPage = () => {
  const auth = useSelector(state => state.auth)
  const id = useSelector(state => state.sidebar.projectId)
  const { loading, request, error, clearError } = useHttp()
  const message = useMessage()
  const [project, setProject] = useState(null)
  const [blockId, setBlockId] = useState(-1)
  const [show, setShow] = useState(false)

  useEffect(() => {
    message(error)
    clearError()
  }, [error, message, clearError])

  const getProject = useCallback(async (token, id) => {
    try {
      if (token && id) {
        const data = await request(`/api/project/blocks/${id}`, 'GET', null, { authorization: 'Bearer ' + token })
        setProject(data.project)
      }
    } catch (e) {
      console.log(e)
    }
  }, [request])

  useEffect(() => {
    (async () => {
      await getProject(auth.token, id)
    })()

  }, [getProject, id, auth.token])

  const updateOnChange = async () => {
    await getProject(auth.token, id)
    // hide sidebar
    setShow(false)
  }

  const showModal = (id = -new Date()) => {
    setBlockId(id)
    // show sidebar
    setShow(true)
  }

  const createBlock = () => {
    showModal()
  }

  const deleteHandler = async id => {
    try {
      const data = await request(`/api/block/${id}`, 'DELETE', null, { authorization: 'Bearer ' + auth.token })
      message(data.message)
      await updateOnChange()
    } catch (e) {
      console.log(e)
    }
  }

  const onClose = () => {
    setShow(false)
  }

  if (!project || loading) {
    return <Loader/>
  }

  return (
    <>
      <div className="row justify-content-between mb-3">
        <div className="col">
          <h5 className="mb-0">Функциональные блоки</h5>
          <p className="text-gray">Создавайте и редактируйте функциональные блоки</p>
        </div>

        <div className="col align-self-end text-end">
          <button
            className="btn btn-outline-my mb16px"
            type="button"
            onClick={createBlock}
          >Создать блок</button>
        </div>
      </div>

      <div className="row mb-3 h-100">

          {project && project.blocks && project.blocks.length ? (
            <div className="col-12">
              <table className="table">
                <thead>
                <tr>
                  <th className="text-gray">Тип</th>
                  <th className="text-gray">Название и описание</th>
                  <th className="text-gray">Сложность</th>
                  <th className="text-gray">Действия</th>
                </tr>
                </thead>
                <tbody className="table-hover">
                {project && project.blocks && project.blocks.length ? project.blocks.map(c => {
                  return (
                    <tr
                      key={c.id}
                    >
                      {/*<td className="py-0" style={{ width: '22px' }}></td>*/}
                      <td>{c.type === 'Action' ?
                        <Icon name="flash" size="20px"/> : <Icon name="box" size="20px"/>}{c.type}</td>
                      <td>
                        <p className="mb-0">{c.name || '-'}</p>
                        <p className="text-gray mb-0">{c.description}</p>
                      </td>
                      <td>{c.difficulty === 'Easy' ?
                        <Icon name="Easy" size="18px"/> : c.difficulty === 'Medium' ? <Icon name="Medium" size="18px"/> : <Icon name="Hard" size="18px"/>}{c.difficulty}</td>
                      <td>
                        <i
                          style={{ cursor: 'pointer' }}
                          className="fa fa-pencil-square-o opacity-0"
                          aria-hidden="true"
                          // onClick={() => {history.push(`/project/${c.projectId}/testCases/${c.id}`)}}
                          onClick={() => {showModal(c.id)}}
                        />
                        <i
                          style={{ cursor: 'pointer' }}
                          className="fa fa-trash-o ps-2 opacity-0"
                          aria-hidden="true"
                          onClick={() => {deleteHandler(c.id)}}
                        />
                      </td>
                    </tr>
                  )
                }) : (<></>)}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="col pt-5">
              <NoTable params={{ title: `В этом проекте пока нет блоков` }}/>
            </div>
          )}

      </div>

      <BlockForm options={{ blockId, project, show }} updateOnChange={updateOnChange} onClose={onClose}/>
    </>
  )
}
