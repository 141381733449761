import React, { useCallback, useEffect, useState } from 'react'
import { useMessage } from '../../../hooks/message.hook'
import { useHttp } from '../../../hooks/http.hook'
import { Loader } from '../../partials/Loader'
import { useSelector } from 'react-redux'
import { useJira } from '../../../hooks/jira.hook'
import { useSprint } from '../../../hooks/sprint.hook'
import { InlineLoader } from '../../partials/InlineLoader'


export const SprintCompare = () => {
  const auth = useSelector(state => state.auth)
  const id = useSelector(state => state.sidebar.projectId)
  const { loading, request, error, clearError } = useHttp()
  const { jiraLoading, getJiraEpics, calcEpicProgress, getHistory } = useJira()
  const {computeDiff3Sprints} = useSprint()
  const message = useMessage()
  const [project, setProject] = useState(null)
  const [jiraTasks, setJiraTasks] = useState([])
  const [sprints, setSprints] = useState(null)

  useEffect(() => {
    message(error)
    clearError()
  }, [error, message, clearError])

  const getProject = useCallback(async (token, id) => {
    try {
      if (token && id) {
        const data = await request(`/api/project/sprints/${id}`, 'GET', null, { authorization: 'Bearer ' + token })
        setProject(data.project)

        console.log('data.project', data.project)
        // get jira and check connection
        const jira = await getJiraEpics(auth, id, 'dev')
        setJiraTasks(jira.jiraTasks)

        const sprintsStat = await computeDiff3Sprints(data.project.sprints, jira.jiraTasks, id, auth)
        setSprints(sprintsStat)
        console.log('sprintsStat', sprintsStat)
      }
    } catch (e) {
      console.log(e)
    }
  }, [request])

  useEffect(() => {
    (async () => {
      await getProject(auth.token, id)
    })()

  }, [getProject, id, auth.token])

  const setProgress = (stats, period, type) => {
    console.log('stats', stats)
    if (stats) {
      let title, backgroundColor

      switch (period) {
        case 0:
          title = '< 1 часа'
          backgroundColor = '#08979C'
          break
        case 1:
          title = '1 - 4 часа'
          backgroundColor = '#08979C'
          break
        case 2:
          title = '4 - 8 часов'
          backgroundColor = '#08979C'
          break
        case 3:
          title = '> 8 часов'
          backgroundColor = '#D48806'
          break
        default:
          title = '< 1 часа'
          backgroundColor = '#08979C'
      }

      let progress = Math.floor(stats[type][period]*100 / stats.tasksTotal )
      progress = isNaN(progress) ? 0 : progress

      return (
        <>
          <div className="row justify-content-between mb-0">
            <div className="col-auto">
              <span className="" style={{ fontSize: '13px' }}>{title}</span>
            </div>
            <div className="col-auto">
              <span className="text-gray" style={{ fontSize: '13px' }}>{`${stats[type][period]} задач`}</span>
              <span className="" style={{ fontSize: '13px' }}>{` ${progress}%`}</span>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-12">
              <div className="progress" style={{ height: '8px' }}>
                <div className="progress-bar" role="progressbar"
                     style={{ width: `${progress}%`, backgroundColor }} aria-valuemin="0"
                     aria-valuemax="100"/>
              </div>
            </div>
          </div>
        </>
      )
    } else {
      return <></>
    }
  }


  if (!project || loading || jiraLoading) {
    return <Loader/>
  }

  return (
    <>
      {/*Title*/}
      <div className="row justify-content-between mb-3">
        <div className="col">
          <h5 className="mb-0" style={{fontSize: '30px', fontWeight: 700}}>Сравнение эффективности спринтов {!sprints ? <InlineLoader size={20} ml={1}/> : ''}</h5>
          <p className="text-gray" style={{fontSize: '20px'}}>Выберите спринт и сравните его другими </p>
        </div>
      </div>

      {/*Body*/}
      <div className="row">
        {sprints && sprints.length ? sprints.map(s => {
          // console.log('sprint', s)
          return (
          <div className="col-4" key={s.id}>
            <div className="row flex-column">
              <div className="col-auto mb-2">
                <h5>Sprint {s.id}</h5>
              </div>

              <div className="col-auto">
                <p className="" style={{ fontSize: '18px' }}>Время "Реакции"</p>

                {/*< 1 hour*/}
                {s.stats && setProgress(s.stats, 0, 'todo2work')}
                {/*1 - 4 hour*/}
                {s.stats && setProgress(s.stats, 1, 'todo2work')}
                {/*4 - 8 hour*/}
                {s.stats && setProgress(s.stats, 2, 'todo2work')}
                {/*> 8 hour*/}
                {s.stats && setProgress(s.stats, 3, 'todo2work')}

                <p className="text-gray" style={{ fontSize: '13px' }}>Время до момента перевода задачи в статус “В
                  работе”</p>
              </div>
              <div className="col-auto">
                <p className="" style={{ fontSize: '18px' }}>Время "Исполнения"</p>

                {/*< 1 hour*/}
                {s.stats && setProgress(s.stats, 0, 'work2done')}
                {/*1 - 4 hour*/}
                {s.stats && setProgress(s.stats, 1, 'work2done')}
                {/*4 - 8 hour*/}
                {s.stats && setProgress(s.stats, 2, 'work2done')}
                {/*> 8 hour*/}
                {s.stats && setProgress(s.stats, 3, 'work2done')}

                <p className="text-gray" style={{ fontSize: '13px' }}>Время перевода задачи из статуса “В работе”
                  в статус “Сделано”</p>
              </div>

            </div>
          </div>
        )}) : <></>}
      </div>
    </>
  )
}
