import React, { useEffect, useState } from 'react'
import { useHttp } from '../../../hooks/http.hook'
import { useHistory } from 'react-router-dom'
import { useMessage } from '../../../hooks/message.hook'
import { Loader } from '../../partials/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { login, logout } from '../../../redux/actions/auth.actions'
import { setProjectId } from '../../../redux/actions/sidebar.actions'
import { setActiveItem, showSubMenu } from '../../../redux/actions/navbar.actions'

export const ProjectCreatePage = () => {
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const { loading, request, error, clearError } = useHttp()
  const [form, setForm] = useState({
    name: '',
    description: '',
    slug: ''
  })
  const message = useMessage()
  const history = useHistory()

  useEffect(() => {
    message(error)
    if (error === 'Нет авторизации') {
      clearError()
      dispatch(logout())
      history.push('/')
    }
    clearError()
  }, [error, message, clearError, dispatch, history])

  useEffect(() => {
    dispatch(setActiveItem('/settings'))
    dispatch(showSubMenu())
  }, [])

  const changeHandler = event => {
    setForm({ ...form, [event.target.name]: event.target.value })
  }

  const updateHandler = async () => {
    try {
      const data = await request('/api/project/create', 'POST', { ...form }, {
        authorization: 'Bearer ' + auth.token,
        id: auth.user.id
      })
      message(data.message)
      // update user in context
      // get user
      const usr = await request('/api/auth/getWithProjetcs', 'POST', { email: auth.user.email }, { authorization: 'Bearer ' + auth.token })
      dispatch(login({ token: auth.token, user: usr.user }))

      setForm({
        name: '',
        description: '',
        slug: ''
      })

      dispatch(setProjectId(data.id))

      history.push(`/settings`)
    } catch (e) {}
  }

  if (!auth.user) {
    return <Loader/>
  }

  return (
    <>
      <div className="row mb-4">
        <div className="col">
          <h5>Создание проекта</h5>
          <p className="text-gray">Создайте новый проект для управления разработкой, деплоем и поддержкой</p>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-5">
          <div className="input-field">
            <input
              placeholder="Название проекта"
              type="text"
              id="name"
              className="form-control"
              name="name"
              value={form.name}
              autoComplete="off"
              onChange={changeHandler}
            />
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-5">
          <div className="input-field">
            <textarea
              id="description"
              name="description"
              value={form.description}
              onChange={changeHandler}
              className="form-control"
            />
            <label htmlFor="description">Описание проекта (необязательно)</label>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-5">
          <button
            className="btn btn-bg-blue w-100"
            onClick={updateHandler}
            disabled={loading}
          >
            Создать проет
          </button>
        </div>
      </div>
    </>
  )

}
