import React, { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setModeProfile, setModeProject, setProjectId } from '../../redux/actions/sidebar.actions'
import { useHttp } from '../../hooks/http.hook'
import { login, logout } from '../../redux/actions/auth.actions'
import { hideSubMenu, setActiveItem, setBudge, setNavbarItems } from '../../redux/actions/navbar.actions'
import { profileMenu, projectMenu } from '../../redux/actions/menu'
import { useJira } from '../../hooks/jira.hook'
import { useJiraSupportStats } from '../../hooks/jiraSupportStats.hook'
import { useSprintStats } from '../../hooks/sprintStats.hook'

export const Sidebar = () => {
  const dispatch = useDispatch()
  const mode = useSelector(state => state.sidebar.mode)
  const auth = useSelector(state => state.auth)
  const projectId = useSelector(state => state.sidebar.projectId)
  const history = useHistory()
  const { request } = useHttp()
  const { getJiraEpics } = useJira()
  const { getJiraStats } = useJiraSupportStats()
  const { getSprintStats } = useSprintStats()

  // const projectId = useSelector(state => state.sidebar.projectId)

  useEffect(() => {
    try {
      if (projectId) document.getElementById(`prj-${projectId}`).classList.add('sb-active')
    } catch (e) {
      console.log(e)
    }
  }, [projectId])

  const changeMode = () => {
    if (mode === 'project') {
      document.getElementById('cog').classList.add('cog-active')
      dispatch(setModeProfile())
      dispatch(setNavbarItems(profileMenu))
      dispatch(hideSubMenu())
      history.push('/user/profile')
      return
    }

    document.getElementById('cog').classList.remove('cog-active')
    dispatch(setModeProject())
    // todo if projectId > 0, redirect to current project
    history.push('/')
    dispatch(setNavbarItems(projectMenu))
    dispatch(setActiveItem('/'))
  }

  const setSidebarModeProject = () => {
    // change sidebar mode to project
    dispatch(setModeProject())
    document.getElementById('cog').classList.remove('cog-active')

    // set navbar menu items
    dispatch(setNavbarItems(projectMenu))
  }

  const getProject = useCallback(async (token) => {
    try {
      if (token) {
        const u = await request(`/api/auth/check`, 'POST', { userId: auth.user.id }, { authorization: 'Bearer ' + token })
        if (!u.isUser) {
          dispatch(logout())
          history.push('/')
          return
        }

        const data = await request(`/api/project/projects`, 'POST', null, { authorization: 'Bearer ' + token })
        // setProjects(data.projects)

        const user = { ...auth.user, projects: data.projects }
        dispatch(login({ token, user }))
      }
    } catch (e) {
      console.log(e)
    }
  }, [request, history, dispatch])

  useEffect(() => {
    try {
      (async () => {
        await getProject(auth.token)
      })()
    } catch (e) {
      console.log(e)
    }
  }, [getProject, auth.token])

  const setBadges = async (prjId) => {
    // todo set team
    // set support
    const jira = await getJiraEpics(auth, prjId, 'issues')
    if (jira) {
      getJiraStats(jira.jiraTasks)
    }
    // set sprints
    await getSprintStats(prjId)
  }

  const menuClickHandler = async (e, prjId) => {
    await getProject(auth.token)

    let els = document.querySelectorAll('.sbEl')
    els.forEach(el => el.classList.remove('sb-active'))

    // here set active-menu
    e.target.classList.add('sb-active')

    setSidebarModeProject()

    // set active projectId to store
    history.push('/')
    dispatch(hideSubMenu())
    dispatch(setActiveItem('/'))
    dispatch(setProjectId(prjId))
    await setBadges(prjId)
  }

  const createProject = () => {
    let els = document.querySelectorAll('.sbEl')
    els.forEach(el => el.classList.remove('sb-active'))

    history.push('/project/create')
    setSidebarModeProject()
  }

  return (
    <div id="sidebar" className="d-flex flex-column justify-content-start">
      {auth.user && auth.user.projects.length ? auth.user.projects.map(prj => {
        return (
          <React.Fragment key={prj.id}>
            <div id={`prj-${prj.id}`} className="sbEl" onClick={e => menuClickHandler(e, prj.id)}>{prj.name.slice(0,2).toUpperCase()}</div>
          </React.Fragment>
        )
      }) : (
        <>
          {/*<div id={`prj-1`} className="sbEl" onClick={e => menuClickHandler(e, 1)}>{'sbarro'.slice(0,2).toUpperCase()}</div>*/}
          {/*<div id={`prj-2`} className="sbEl" onClick={e => menuClickHandler(e, 2)}>NO</div>*/}
          {/*<div id={`prj-3`} className="sbEl" onClick={e => menuClickHandler(e, 3)}>OP</div>*/}
        </>
      )}

      {/*loader*/}
      {/*{loading && <Loader size={40} pt={1} pb={1}/>}*/}

      <div className="sbAdd" onClick={createProject}>+</div>

      <div className="cog">
        <i id="cog" className="fa fa-cog" aria-hidden="true" onClick={changeMode}/>
      </div>
    </div>
  )
}