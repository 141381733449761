export const profileMenu = [
  {
    name: 'Профиль',
    path: '/user/profile',
    active: true,
    sub: []
  },
  {
    name: 'Изменение пароля',
    path: '/user/password',
    active: false,
    sub: []
  }]

export const projectMenu = [
  {
    name: 'Главная',
    path: '/',
    badge: '',
    active: true,
    sub: []
  },
  {
    name: 'Архитектура',
    path: '/arch',
    badge: '',
    active: false,
    sub: [
      {
        name: 'Схема архитектуры',
        path: '/arch',
        badge: '',
        active: true
      },
      {
        name: 'Функциональные блоки',
        path: '/arch/func',
        badge: '',
        active: false
      },
    ]
  },
  {
    name: 'Roadmap',
    path: '/roadmap',
    badge: '',
    active: false,
    sub: []
  },
  {
    name: 'Спринты',
    path: '/sprint',
    badge: '',
    active: false,
    sub: [
      {
        name: 'Все',
        path: '/sprint',
        badge: '',
        active: true
      },
      {
        name: 'Текущие',
        path: '/sprint/curr',
        badge: '',
        active: false
      },
      {
        name: 'Запланировано',
        path: '/sprint/planned',
        badge: '',
        active: false
      },
      {
        name: 'Завершено',
        path: '/sprint/finished',
        badge: '',
        active: false
      },
      {
        name: 'Настройки',
        path: '/sprint/settings',
        badge: '',
        active: false
      },
    ]
  },
  {
    name: 'Команда',
    path: '/team',
    badge: '',
    active: false,
    sub: []
  },
  {
    name: 'Поддержка',
    path: '/support',
    badge: '',
    active: false,
    sub: [
      {
        name: 'Все обращения',
        path: '/support',
        badge: '',
        active: true
      },
      {
        name: 'Backlog',
        path: '/support/backlog',
        badge: '',
        active: false
      },
      {
        name: 'In progress',
        path: '/support/progress',
        badge: '',
        active: false
      },
      {
        name: 'In review',
        path: '/support/review',
        badge: '',
        active: false
      },
      {
        name: 'Done',
        path: '/support/done',
        badge: '',
        active: false
      },
      {
        name: 'Canceled',
        path: '/support/canceled',
        badge: '',
        active: false
      },
    ]
  },
  {
    name: 'Настройки',
    path: '/settings',
    badge: '',
    active: false,
    sub: [
      {
        name: 'Общее',
        path: '/settings',
        badge: '',
        active: true
      },
      {
        name: 'Интеграции',
        path: '/settings/integration',
        badge: '',
        active: false
      },
      {
        name: 'Уведомления и отчёты',
        path: '/settings/reports',
        badge: '',
        active: false
      },
    ]
  }]