import React, { useState, useEffect, useMemo } from 'react'
import { useHttp } from '../../../hooks/http.hook'
import { useMessage } from '../../../hooks/message.hook'
import { useSelector } from 'react-redux'
import { Offcanvas } from 'react-bootstrap'
import { useSprint } from '../../../hooks/sprint.hook'
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar'
import { useJira } from '../../../hooks/jira.hook'
import { Bar } from 'react-chartjs-2'
import { useDate } from '../../../hooks/date.hook'
import { InlineLoader } from '../../partials/InlineLoader'
import { MergeIcon } from '../../partials/MergeIcon'
import { Loader } from '../../partials/Loader'

export const SprintDetails = ({ options, updateOnChange, onClose }) => {
  const auth = useSelector(state => state.auth)
  const id = useSelector(state => state.sidebar.projectId)
  const { request, error, clearError, silentRequest, loading } = useHttp()
  const message = useMessage()
  const { calcProgress, sprintStatus, sprintDateDiff, team, filterJiraTask, getRecommendations } = useSprint()
  const { getJiraEpics, jiraLoading } = useJira()
  const { getDaysArray, dateToStr } = useDate()
  const [jiraStatuses, setJiraStatuses] = useState([])
  const [jiraTasks, setJiraTasks] = useState(null)
  const [chartData, setChartData] = useState(null)
  const [showAdd, setShowAdd] = useState(false)
  const [progress, setProgress] = useState(0)
  const [teamProgress, setTeamProgress] = useState({})
  const [recomm, setRecomm] = useState([])
  const [mergeReq, setMergeReq] = useState(null)
  const [mergeComments, setMergeComments] = useState(null)
  const [mergeCommits, setMergeCommits] = useState(null)
  // const [releaseMerge, setReleaseMerge] = useState(null)
  const [releaseCommits, setReleaseCommits] = useState(null)

  const [tasks, setTasks] = useState([])
  const [removedTasks, setRemovedTasks] = useState([])


  useEffect(() => {
    message(error)
    clearError()
  }, [error, message, clearError])

  const calculateChart = () => {
    // setChart
    const chart = {
      labels: [],
      datasets: [
        {
          type: 'line',
          label: 'scope',
          data: [], // tasks finished (on date ^^^), check by target date (duedate)
          fill: false,
          backgroundColor: '#90959d',
          borderColor: '#90959d',
        },
        {
          type: 'line',
          label: 'progress',
          data: [], // tasks really finished (on date ^^^), check by status == ready &&  fields.updated
          fill: false,
          backgroundColor: '#6e7ad7',
          borderColor: '#6e7ad7',
        },
        {
          type: 'bubble',
          radius: 7,
          label: 'target',
          data: [], // tasks really finished (on date ^^^), check by status == ready &&  fields.updated
          fill: false,
          backgroundColor: 'red',
          borderColor: 'red',
        },
      ],
    }
    // here set chartData

    let start = new Date(options.sprint.startDay)
    let end = new Date(options.sprint.endDay)
    let labels = getDaysArray(start, end)
    chart.labels = [...labels.map(d => d.toLocaleDateString('ru-RU', {
      month: 'short',
      day: '2-digit'
    }))]

    // max yAxis
    let maxTasks = tasks.length
    // and set Red point to max duedate and max tasks
    chart.datasets[2].data = [{
      x: labels[labels.length - 1].toLocaleDateString('ru-RU', {
        month: 'short',
        day: '2-digit'
      }), y: maxTasks
    }]

    let dueTasks = 0
    let readyTasks = 0

    let filteredJiraTasks = filterJiraTask(tasks, jiraTasks)
    // let filteredJiraTasks = tasks.map(el => {
    //   return jiraTasks.find(jt => el.jiraTaskId === jt.id)
    // })
    // filteredJiraTasks = filteredJiraTasks.filter(el => el)

    labels.forEach(date => {
      const dateStr = dateToStr(date)
      // here cycle by tasks to check task.duedate and fields.resolutiondate = finished date!
      // count of tasks having duedate === date on x axis
      dueTasks += filteredJiraTasks.filter(t => t.duedate === dateStr).length
      chart.datasets[0].data.push(dueTasks)

      // count of tasks having resolutiondate === date on x axis
      readyTasks += filteredJiraTasks.filter(t => t.fields.resolutiondate === date).length
      if (date <= new Date()) chart.datasets[1].data.push(readyTasks)
    })

    setChartData(chart)
  }

  useEffect(() => {
    (
      async () => {
        // Get jira
        const jira = await getJiraEpics(auth, id, 'dev')

        if (jira.message) message(jira.message)
        setJiraStatuses(jira.jiraStatuses)
        const JT = jira.jiraTasks ? jira.jiraTasks : []
        setJiraTasks(JT)
        // console.log('jira.jiraTasks', jira.jiraTasks)

        if (options.sprint.SprintTasks.length) {
          setTasks(options.sprint.SprintTasks)
          let teamProg = team(options.sprint.SprintTasks, JT)
          setTeamProgress(teamProg)
          // here calc recommend
          setRecomm([...getRecommendations(options.sprint, JT, teamProg)])

        }
        if (options.sprint) {
          setProgress(calcProgress(options.sprint, JT))
        }

        // get list of requests/ comments and other
        if (options.project) {
          const { pullsList } = await request(`/api/git/getListPullRequests`, 'POST', {
            repo: options.project.gitRepo,
            gitToken: options.project.gitToken,
          }, { authorization: 'Bearer ' + auth.token })

          if (pullsList.data && pullsList.data.length) {
            let mergeRequest = pullsList.data.filter(el => el.base.ref === options.sprint.branch)
            let releaseBuild = pullsList.data.filter(el => el.head.ref === options.sprint.branch && el.base.ref === options.project.gitMaster && el.state === 'closed')
            
            if (mergeRequest && mergeRequest.length) mergeRequest = mergeRequest.reduce(function (prev, current) {
              return (new Date(prev.created_at) > new Date(current.created_at)) ? prev : current
            })

            setMergeReq(mergeRequest)

            const { commentsList } = await request(`/api/git/getCommentsForIssue`, 'POST', {
              repo: options.project.gitRepo,
              gitToken: options.project.gitToken,
              issue_number: mergeRequest.number
            }, { authorization: 'Bearer ' + auth.token })

            if (commentsList && commentsList.data.length) setMergeComments(commentsList.data)

            // for build status
            const { listCommits } = await request(`/api/git/getCommitsForIssue`, 'POST', {
              repo: options.project.gitRepo,
              gitToken: options.project.gitToken,
              pull_number: mergeRequest.number
            }, { authorization: 'Bearer ' + auth.token })

            if (listCommits && listCommits.data.length) setMergeCommits(listCommits.data[listCommits.data.length - 1])

            if (releaseBuild && releaseBuild.length) {
              // setReleaseMerge(releaseBuild[0])
              // for release build status
              const { listCommits } = await request(`/api/git/getCommitsForIssue`, 'POST', {
                repo: options.project.gitRepo,
                gitToken: options.project.gitToken,
                pull_number: releaseBuild[0].number
              }, { authorization: 'Bearer ' + auth.token })

              if (listCommits && listCommits.data.length) setReleaseCommits(listCommits.data[listCommits.data.length - 1])
            }


            // console.log('pullsList', pullsList.data)
            // console.log('Release', releaseBuild)
            // console.log('this sprint req', mergeRequest)
            // console.log('this sprint req comments', commentsList.data)
            // console.log('this sprint req Commits', listCommits.data)
          }

        }

      }
    )()
  }, [])

  // useEffect(() => {
  //   console.log('teamProgress', teamProgress)
  // }, [teamProgress])

  useEffect(() => {
    if (jiraTasks && jiraTasks.length) {
      setTeamProgress(team(tasks, jiraTasks))
      calculateChart()
    }
    // console.log(team(tasks, jiraTasks))
  }, [tasks])

  const updateHandler = async (e) => {
    setShowAdd(false)

    setTasks(prev => ([...prev, {
      id: +new Date() * -1,
      sprintId: options.sprint.id,
      jiraTaskId: e.target.value,
    }]))
  }

  const beforeClose = async () => {
    // save changes on close
    // save tasks with id < 0
    // remove from db removedTasks
    try {
      if (tasks.filter(el => el.id < 0).length || removedTasks.length) {
        const data = await request(`/api/sprint/${options.sprint.id}`, 'PUT', {
          tasks: tasks.filter(el => el.id < 0),
          removedTasks
        }, { authorization: 'Bearer ' + auth.token })
        message(data.message)
      }
    } catch (e) {
      console.log(e)
    }

    updateOnChange()
    // onClose()
  }

  const removeTask = (taskId) => {
    console.log(taskId)
    if (taskId >= 0) setRemovedTasks(prev => ([...prev, taskId]))
    // options.sprint.SprintTasks = options.sprint.SprintTasks.filter(el => el.id !== taskId)
    setTasks(tasks.filter(el => el.id !== taskId))
  }

  // if (!options.sprint || !jiraTasks) {
  //   return <Loader/>
  // }

  return (
    <Offcanvas show={options.show} onHide={beforeClose} placement={'end'}>
      <Offcanvas.Header closeButton style={{ paddingBottom: 0 }}>
        {/*<Offcanvas.Title><span style={{fontSize: '24px'}}>Создание/Редактирование блока</span></Offcanvas.Title>*/}
        <Offcanvas.Title>Данные по "Спринт №{options.sprint.id}" {jiraLoading ? <InlineLoader size={20} ml={1}/> : ''}
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body style={{ paddingTop: 0 }}>

        <hr style={{ display: 'block', border: '0', borderTop: '1px solid #ced4da' }}/>

        {/*SPRINT*/}
        <div className="row mb-3">
          <div className="col-12">
            {/*Progress & Date Start*/}
            <div className="row justify-content-between mb-3">
              <div className="col-auto align-self-start">
                <div className="row">
                  <div className="col-auto align-self-center">
                    <div
                      className="d-flex justify-content-center"
                      style={{
                        width: '40px',
                        height: '40px',
                        border: !progress ? '1px solid rgba(0,0,0, .15)' : '1px solid #6A4EF4',
                        borderRadius: '50%'
                      }}
                    >
                      <div className="align-self-center" style={{ width: '85%', height: '85%' }}>
                        <CircularProgressbarWithChildren
                          strokeWidth={50}
                          styles={buildStyles({
                            strokeLinecap: 'butt',
                            pathColor: '#6A4EF4',
                            trailColor: 'transparent'
                          })}
                          value={progress}
                        >
                        </ CircularProgressbarWithChildren>
                      </div>
                    </div>
                  </div>
                  <div className="col-auto">
                    <p className="mb-0" style={{ fontSize: '15px', fontWeight: 600 }}>
                      {new Date(options.sprint.startDay).toLocaleDateString('ru-RU', {
                        month: 'short',
                        day: '2-digit'
                      })} - {new Date(options.sprint.endDay).toLocaleDateString('ru-RU', {
                      month: 'short',
                      day: '2-digit'
                    })}
                    </p>
                    <p className="text-gray mb-0" style={{ fontSize: '13px' }}>
                      Спринт {options.sprint.id}, {sprintStatus(options.sprint)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-auto align-self-end">
                {sprintDateDiff(options.sprint)}
              </div>
            </div>

            {/*Chart*/}
            <div className="row mb-3">
              <div className="col-12">
                {!jiraLoading && !loading && tasks.length && chartData ?
                  <>
                    {/*Chart*/}
                    <div className="row clear-row justify-between align-center w-100">
                      <div className="col-12 w-100">
                        <Bar data={chartData}/>
                      </div>
                    </div>
                  </>
                  :
                  <Loader />
                }
              </div>
            </div>

            {/*TASKS*/}
            <div className="row mb-3">
              <div className="col-12">
                {/*{!options.sprint.SprintTasks.length ?*/}
                {!tasks.length ?
                  <>
                    <div className="row">
                      <div className="col-auto pe-0">
                        <span style={{
                          display: 'inline-block',
                          width: '28px',
                          height: '28px',
                          minWidth: '28px',
                          minHeight: '28px',
                          fontSize: '23px',
                          lineHeight: '23px',
                          textAlign: 'center',
                          color: '#D48806',
                          border: '1px solid #D48806',
                          borderRadius: '50%',
                        }}>!</span>
                      </div>
                      <div className="col-auto">
                        <p className="mb-2" style={{ fontSize: '18px', fontWeight: 600 }}>Информация по задачам</p>
                      </div>
                    </div>
                    <hr className="my-1" style={{ display: 'block', border: '0', borderTop: '1px solid #ced4da' }}/>
                    <p className="text-gray" style={{ fontSize: '15px' }}>Важно! Для начала спринта вам необходимо
                      добавить задачи</p>
                  </>
                  :
                  <>
                    <div className="row">
                      <div className="col-12">
                        <p className="mb-2" style={{ fontSize: '18px', fontWeight: 600 }}>Информация по задачам</p>
                        <hr className="my-1" style={{ display: 'block', border: '0', borderTop: '1px solid #ced4da' }}/>
                      </div>
                    </div>

                    {/*HERE ADDED TASKS*/}
                    <div className="row mb-3">
                      <div className="col-12">
                        {/*{options.sprint.SprintTasks.map(t => {*/}
                        {tasks.map(t => {
                          let jt = jiraTasks.find(el => el.id === t.jiraTaskId)
                          return (
                            <React.Fragment key={t.id}>
                              {jt ?
                                <div className="row justify-content-between">
                                  <div className="col-auto">
                                    <p className="text-gray mb-1" style={{ fontSize: '13px' }}>
                                      {jt.summary} · {jt.status.name} · {Math.ceil(jt.aggregateprogress.total / 3600)}ч.
                                      · {jt.fields.assignee && jt.fields.assignee.displayName ? jt.fields.assignee.displayName : jt.fields.creator.displayName}
                                    </p>
                                  </div>
                                  <div className="col-auto">
                                    <span className="text-gray mb-1" style={{ cursor: 'pointer' }}
                                          onClick={() => {removeTask(t.id)}}>&times;</span>
                                  </div>
                                </div>
                                :
                                <></>
                              }
                            </React.Fragment>
                          )
                        })}
                      </div>
                    </div>
                  </>
                }

                {/*SELECT FOR ADD TASK*/}
                {showAdd ?
                  <>
                    <div className="row mb-3">
                      <div className="col-12">
                        <div className="input-field">
                          <select
                            id="jiraTask"
                            name="jiraTask"
                            defaultValue="-"
                            className="form-select"
                            onChange={updateHandler}
                          >
                            <option value="-" disabled>-</option>
                            {jiraTasks.length && jiraTasks.filter(ts => ts.status.name === 'К выполнению').length ?
                              jiraTasks.filter(ts => ts.status.name === 'К выполнению').map(ts => {
                                return (
                                  <React.Fragment key={ts.id}>
                                    <option value={ts.id}>{ts.summary}</option>
                                  </React.Fragment>
                                )
                              })
                              :
                              <></>
                            }
                          </select>
                        </div>
                      </div>
                    </div>
                  </>
                  :
                  <></>
                }

                {/*ADD TASK BTN*/}
                <div className="row">
                  <div className="col">
                    <button className="btn btn-outline-my w-100" onClick={() => {setShowAdd(true)}}>Добавить задачу
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/*TEAM PROGRESS*/}
            <div className="row justify-between align-center mb-3">
              <div className="col-12">
                <p className="mb-2" style={{ fontSize: '18px', fontWeight: 600 }}>Прогресс по членам команды</p>
                <hr className="my-1" style={{ display: 'block', border: '0', borderTop: '1px solid #ced4da' }}/>

                {tasks.length && jiraTasks && Object.keys(teamProgress).length ? Object.keys(teamProgress).filter(el => el !== 'ready').map(usr => {
                    return (
                      <React.Fragment key={usr}>

                        <div className="row justify-content-between">

                          <div className="col-auto">
                            <div className="row justify-content-start">
                              <div className="col-auto align-self-center pe-0">
                                <div style={{
                                  width: '10px',
                                  height: '10px',
                                  borderRadius: '50%',
                                  backgroundColor: '#d4c05b',
                                  margin: 'auto'
                                }}/>
                              </div>
                              <div className="col-auto">
                                <p className="mb-1" style={{ fontSize: '14px' }}>{usr}</p>
                              </div>
                            </div>
                          </div>

                          <div className="col-auto">
                            <p className="mb-1"
                               style={{ fontSize: '14px' }}>{teamProgress[usr].ready} / {teamProgress[usr].total} tasks</p>
                          </div>
                        </div>

                      </React.Fragment>
                    )
                  })
                  :
                  <>
                    <span className="text-gray mb-1" style={{ fontSize: '15px' }}>Нет команды</span>
                  </>
                }
              </div>
            </div>

            {/*RECOMMENDATIONS*/}
            <div className="row mb-3">
              <div className="col-12">
                <p className="mb-2" style={{ fontSize: '18px', fontWeight: 600 }}>Рекомендации</p>
                <hr className="my-1" style={{ display: 'block', border: '0', borderTop: '1px solid #ced4da' }}/>

                {recomm.length ?
                  recomm.map(r => {
                    return (
                      <p className="text-gray mb-1" style={{ fontSize: '14px' }} key={r}>{r}</p>
                    )
                  })
                  :
                  <p className="text-gray mb-1" style={{ fontSize: '14px' }}>Рекомендации отсутствуют</p>
                }

              </div>
            </div>

            {/*Merge Requests*/}
            <div className="row mb-1">
              <div className="col-12">
                <p className="mb-2" style={{ fontSize: '18px', fontWeight: 600 }}>Merge Requests</p>
                <hr className="my-1" style={{ display: 'block', border: '0', borderTop: '1px solid #ced4da' }}/>
                {mergeReq && mergeReq.head ?
                  <>
                    {/*Created*/}
                    <div className="row align-content-center">
                      <div className="col-4">
                        <p>{options.project.sprintSetting && options.project.sprintSetting.requestOpen ? options.project.sprintSetting.requestOpen : mergeReq.state}</p>
                      </div>
                      <div className="col-auto">
                        <MergeIcon />
                      </div>
                      <div className="col-auto">
                        {mergeReq.head.ref}
                      </div>
                    </div>
                    {/*Comments*/}
                    {mergeComments && mergeComments.length ?
                      <div className="row align-content-center">
                        <div className="col-4">
                          <p>{options.project.sprintSetting && options.project.sprintSetting.requestHaveComments ? options.project.sprintSetting.requestHaveComments : mergeReq.state}</p>
                        </div>
                        <div className="col-auto">
                          <MergeIcon color={'#1890FF'}/>
                        </div>
                        <div className="col-auto">
                          {mergeReq.head.ref}
                        </div>
                      </div>
                      : <></>
                    }
                    {/*Merged*/}
                    {mergeReq.state === 'closed' ?
                      <div className="row align-content-center">
                        <div className="col-4">
                          <p>{options.project.sprintSetting && options.project.sprintSetting.requestMerged ? options.project.sprintSetting.requestMerged : mergeReq.state}</p>
                        </div>
                        <div className="col-auto">
                          <MergeIcon color={'#08979C'}/>
                        </div>
                        <div className="col-auto">
                          {mergeReq.head.ref}
                        </div>
                      </div>
                      : <></>
                    }
                  </>
                  :
                  <p className="text-gray mb-1" style={{ fontSize: '14px' }}>Merge Request-ы отсутствуют</p>
                }


              </div>
            </div>

            {/*Build Status*/}
            <div className="row mb-3">
              <div className="col-12">
                <p className="mb-2" style={{ fontSize: '18px', fontWeight: 600 }}>Build Status</p>
                <hr className="my-1" style={{ display: 'block', border: '0', borderTop: '1px solid #ced4da' }}/>

                {mergeCommits ?
                  <p className="mb-0"><i className="fa fa-check-circle-o" aria-hidden="true" style={{color: '#00D17C'}}/> 1 из 1 успешно - "{mergeCommits.commit.message.substring(1)}"</p>
                  :
                  <p className="text-gray mb-1" style={{ fontSize: '14px' }}>Билды отсутствуют</p>
                }

              </div>
            </div>

            {/*Release Build Status*/}
            <div className="row mb-3">
              <div className="col-12">
                <p className="mb-2" style={{ fontSize: '18px', fontWeight: 600 }}>Release Build Status</p>
                <hr className="my-1" style={{ display: 'block', border: '0', borderTop: '1px solid #ced4da' }}/>

                {releaseCommits ?
                  <p className="mb-0"><i className="fa fa-check-circle-o" aria-hidden="true" style={{color: '#00D17C'}}/> 1 из 1 успешно - "{releaseCommits.commit.message}"</p>
                  :
                  <p className="text-gray mb-1" style={{ fontSize: '14px' }}>Релиз билд отсутствует</p>
                }


              </div>
            </div>
          </div>
        </div>

      </Offcanvas.Body>
    </Offcanvas>
  )
}