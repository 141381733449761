import { SET_MODE_PROFILE, SET_MODE_PROJECT, SET_PROJECT_ID } from '../types/types'

const initialState = {
  mode: 'project', // profile
  projectId: 0,
}

export const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MODE_PROJECT:
      return { ...state,  mode: 'project'}
    case SET_MODE_PROFILE:
      return { ...state,  mode: 'profile'}
    case SET_PROJECT_ID:
      return { ...state,  projectId: action.payload}
    default:
      return state
  }
}