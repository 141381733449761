export const useDate = () => {

  const getClosest = (dayOfWeek) => {
    let date = new Date(),
      targetDay = dayOfWeek, // пятница, начиная с вс=0
      targetDate = new Date(),
      delta = targetDay - date.getDay()
    if (delta >= 0) {targetDate.setDate(date.getDate() + delta)} else {targetDate.setDate(date.getDate() + 7 + delta)}

    return targetDate
  }

  const getDaysArray = (startDate, endDate) => {
    let dates = []
    let currentDate = startDate;
    while (currentDate <= endDate) {
      dates.push(new Date (currentDate));
      currentDate = addDays(currentDate, 1);
    }
    return dates
  }

  /**
   *
   * @param date
   * @returns {string} 'YYYY-mm-dd'
   */
  const dateToStr = (date) => {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2)
      month = '0' + month
    if (day.length < 2)
      day = '0' + day

    return [year, month, day].join('-')
  }

  const daysBetween = (startDate, endDate) => {
    if (typeof startDate === 'string') startDate = new Date(startDate)
    if (typeof endDate === 'string') endDate = new Date(endDate)
    return Math.round((endDate - startDate) / (1000 * 60 * 60 * 24))
  }

  const addDays = (startDate, days) => {
    if (typeof startDate === 'string') startDate = new Date(startDate)
    const endDate = new Date(startDate)
    endDate.setDate(startDate.getDate() + days)
    return endDate
  }

  const whereDate = (startDate, endDate, checkDate = new Date()) => {
    if (typeof checkDate === 'string') checkDate = new Date(checkDate)
    if (typeof endDate === 'string') endDate = new Date(endDate)
    if (typeof startDate === 'string') startDate = new Date(startDate)
    if (checkDate >= startDate && checkDate <= endDate) {
      return 'in period'
    }
    if (checkDate < startDate) return 'before'
    if (checkDate > endDate) return 'after'
  }

  return { getClosest, daysBetween, addDays, whereDate, getDaysArray, dateToStr }
}