import {
  SET_NAVBAR_BUDGE,
  SET_NAVBAR_HIDE_SUB,
  SET_NAVBAR_ITEMS,
  SET_NAVBAR_ITEMS_ACTIVE,
  SET_NAVBAR_SHOW_SUB,
  SET_NAVBAR_SUBITEMS_ACTIVE
} from '../types/types'

const initialState = {
  items: [],
  showSub: false
}

export const navbarReducer = (state = initialState, action) => {
  let sub, height, doubleHeight
  switch (action.type) {
    case SET_NAVBAR_ITEMS:
      return { ...state, items: action.payload }
    case SET_NAVBAR_ITEMS_ACTIVE:
      return {
        ...state, items: state.items.map(item => {
          item.active = item.path === action.payload

          if (item.sub.length) {
            item.sub = item.sub.map(el => {
              el.active = false
              return el
            })
          }

          if (item.active && item.sub.length) item.sub[0].active = true
          return item
        })
      }
    case SET_NAVBAR_SUBITEMS_ACTIVE:
      return {
        ...state, items: state.items.map(item => {
          if (item.active && item.sub.length) item.sub = item.sub.map(sub => {
            sub.active = sub.path === action.payload
            return sub
          })
          return item
        })
      }
    case SET_NAVBAR_SHOW_SUB:
      sub = document.getElementById('page-body')
      doubleHeight = getComputedStyle(document.documentElement).getPropertyValue('--navHeightDouble')
      sub.style.marginTop = doubleHeight
      return { ...state, showSub: true }
    case SET_NAVBAR_HIDE_SUB:
      sub = document.getElementById('page-body')
      height = getComputedStyle(document.documentElement).getPropertyValue('--navHeight')
      sub.style.marginTop = height
      return { ...state, showSub: false }
    case SET_NAVBAR_BUDGE:
      return !action.payload.isSub ?
        {
          ...state, items: state.items.map(item => {
            if (item.path === action.payload.path) item.badge = action.payload.badge
            return item
          })
        }
        :
        {
          ...state, items: state.items.map(item => {
            if (item.sub.length) {
              let changeIdx = item.sub.findIndex(el => el.path === action.payload.path)
              if (changeIdx >= 0) {
                item.sub[changeIdx].badge = action.payload.badge
              }
            }
            return item
          })
        }
    default:
      return state
  }
}