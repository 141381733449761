import React, { useCallback, useState } from 'react'
import { useDate } from './date.hook'
import { useJira } from './jira.hook'

export const useSprint = () => {
  const { getHistory } = useJira()
  const { daysBetween, whereDate } = useDate()

  const filterJiraTask = (tasks, jiraTasks) => {
    return tasks.map(el => {
      return jiraTasks.find(jt => el.jiraTaskId === jt.id)
    }).filter(r => r)
  }

  const getRecommendations = useCallback((sprint, jiraTasks, teamProg) => {
    try {
      if (!sprint || !sprint.SprintTasks.length) {
        return 0
      }

      let recomm = []

      // let filteredJiraTasks = filterJiraTask(sprint.SprintTasks, jiraTasks)
      const datePos = whereDate(sprint.startDay, sprint.endDay, new Date())

      const isLess = (daysBetween(sprint.startDay, new Date()) > daysBetween(new Date(), sprint.endDay))

      if (
        datePos === 'in period' &&
        isLess &&
        teamProg.ready < Math.floor(sprint.SprintTasks.length / 2)
      ) {
        recomm.push('- Рекомендуется увеличить команду, есть риск не успеть выполнить задачи спринта в срок.')
      }

      Object.keys(teamProg).forEach(el => {
        if (el !== 'ready') {
          if (teamProg[el].ready < Math.floor(teamProg[el].total / 2)) recomm.push(`- Рекомендуется оказать помощь или перераспределить задачи ${el}.`)
        }
      })

      return recomm
    } catch (e) {
      console.log(e)
    }
    return []
  }, [])

  const team = useCallback((tasks, jiraTasks) => {
    let teamProgress = {
      ready: 0
    }

    try {
      let filteredJiraTasks = filterJiraTask(tasks, jiraTasks)
      // let filteredJiraTasks = tasks.map(el => {
      //   return jiraTasks.find(jt => el.jiraTaskId === jt.id)
      // })
      // filteredJiraTasks = filteredJiraTasks.filter(el => el)

      filteredJiraTasks.forEach(task => {
        const name = task.fields.assignee && task.fields.assignee.displayName ? task.fields.assignee.displayName : task.fields.creator.displayName
        if (task.status.name === 'Готово') teamProgress.ready += 1
        if (teamProgress.hasOwnProperty(name)) {
          teamProgress[name].total += 1
          if (task.status.name === 'Готово') teamProgress[name].ready += 1
        } else {
          teamProgress = {
            ...teamProgress, [name]: {
              total: 1,
              ready: task.status.name === 'Готово' ? 1 : 0,
            }
          }
        }
      })

    } catch (e) {
      console.log(e)
    }

    return teamProgress
  }, [])

  const calcProgress = useCallback((sprint, jiraTasks) => {
    try {
      if (!sprint || !sprint.SprintTasks.length) {
        return 0
      }

      let filteredJiraTasks = filterJiraTask(sprint.SprintTasks, jiraTasks)
      // let filteredJiraTasks = sprint.SprintTasks.map(el => {
      //   return jiraTasks.find(jt => el.jiraTaskId === jt.id)
      // })
      // filteredJiraTasks = filteredJiraTasks.filter(el => el)

      const progress = Math.floor(filteredJiraTasks.filter(ts => ts.status.name === 'Готово').length * 100 / sprint.SprintTasks.length)
      // const progress = Math.floor(sprint.SprintTasks.filter(ts => ts.status.name === 'Готово').length * 100 / sprint.SprintTasks.length)

      return !isNaN(progress) ? progress : 0
    } catch (e) {
      console.log(e)
    }
    return 0
  }, [])

  const sprintStatus = (sprint) => {
    // 'Черновик' 'Запланировано' 'Идёт' 'Завершено'
    const datePos = whereDate(sprint.startDay, sprint.endDay, new Date())
    switch (datePos) {
      case 'before':
        return sprint.SprintTasks.length > 0 ? 'Запланировано' : 'Черновик'
      case 'after':
        return sprint.SprintTasks.length > 0 ? 'Завершено' : 'Черновик'
      default:
        return sprint.SprintTasks.length > 0 ? 'Идёт' : 'Черновик'
    }
  }

  const sprintDateDiff = (sprint) => {
    let daysB = 0
    let stat = ''
    const datePos = whereDate(sprint.startDay, sprint.endDay, new Date())

    switch (datePos) {
      case 'before':
        daysB = daysBetween(new Date(), sprint.startDay)
        stat = 'до начала'
        break
      case 'after':
        daysB = daysBetween(sprint.endDay, new Date())
        stat = 'назад'
        break
      default:
        daysB = daysBetween(new Date(), sprint.endDay)
        stat = 'до заверш.'
    }

    return (
      <div>
        <p className="mb-0" style={{ fontSize: '15px', fontWeight: 600 }}>{daysB} день</p>
        <p className="text-gray mb-0" style={{ fontSize: '13px' }}>{stat}</p>
      </div>
    )
  } // ; здесь нужна

  const _returnPeriod = (period) => {
    if (period < 1) return 0
    if (period >= 1 && period < 4 ) return 1
    if (period >= 4 && period < 8 ) return 2
    if (period >= 8 ) return 3
    return 3
  }

  const computeSprints = async (sprints, jiraTasks, id, auth) => {
    let plannedAndDraftSprints = []
    let currentSprint = null

    // задачи из трёх последних (завершённых) спринтов
    let tasks = []

    let i = 0
    sprints.forEach(sprint => {
      let status = sprintStatus(sprint)
      if (status === 'Завершено' && i < 3 ) {
        // limit to 3 finished prints with tasks
        if (sprint.SprintTasks.length) {
          tasks = [...tasks, ...sprint.SprintTasks]
          i++
        }
      }
      if (status === 'Запланировано' || status === 'Черновик') {
        plannedAndDraftSprints.push(sprint)
      }
      if (status === 'Идёт') {
        currentSprint = sprint
      }
    })

    let filteredJiraTasks = filterJiraTask(tasks, jiraTasks)
    // разделить по <1 / 1-4 / 4-8 / >8 (переход из to-do в work и из work в done)
    let lastThreeFinishedSprintsStat = {
      tasksTotal: tasks.length,
      todo2work: {
        0: 0,
        1: 0,
        2: 0,
        3: 0
      },
      work2done: {
        0: 0,
        1: 0,
        2: 0,
        3: 0
      }
    }

    // history of all changes in issue
    // console.log('jiraTask', filteredJiraTasks[0])
    for (let i = 0; i < filteredJiraTasks.length; i++) {
      let hist = await getHistory(auth, id, filteredJiraTasks[i].key)

      // console.log(hist
      //   .issueHistory
      //   .values
      //   .filter(el => el.items.length && el.items.find(s => s.field === 'status')))

      let todo2progress = hist
        .issueHistory
        .values
        .filter(el => el.items.length && el.items.find(s => s.field === 'status' && s['fromString'] === 'To Do' && s['toString'] === 'In Progress'))
      let progress2done = hist
        .issueHistory
        .values
        .filter(el => el.items.length && el.items.find(s => s.field === 'status' && s['fromString'] === 'In Progress' && s['toString'] === 'Done'))

      // console.log('todo2progress', todo2progress)
      // console.log('progress2done', progress2done)
      if (todo2progress.length) {
        let period = (new Date(todo2progress[0].created) - new Date(filteredJiraTasks[i].fields.created))/(1000 * 60 * 60)
        lastThreeFinishedSprintsStat.todo2work[`${_returnPeriod(period)}`] += 1
      }

      if (progress2done.length) {
        let period = (new Date(progress2done[progress2done.length - 1].created) - new Date(todo2progress[0].created))/(1000 * 60 * 60)
        lastThreeFinishedSprintsStat.work2done[`${_returnPeriod(period)}`] += 1
      }
    }

    // // datetimes get from hist
    // let first = new Date('2021-11-12T20:59:40.354+0000')
    // let second = new Date('2021-11-12T22:59:40.354+0000')
    // // diff between 2 datetimes in hours
    // console.log((second - first)/(1000 * 60 * 60))

    return { lastThreeFinishedSprintsStat, currentSprint, plannedAndDraftSprints, filteredJiraTasks }
  }

  const computeDiff3Sprints = async (sprints, jiraTasks, id, auth) => {
    let sprints3 = []

    let i = 0
    sprints.forEach(sprint => {
      let status = sprintStatus(sprint)
      // if (status === 'Завершено' && i < 3 ) {
        // limit to 3 finished prints with tasks
        if (sprint.SprintTasks.length) {
          sprints3.push(sprint)
          i++
        }
      // }
    })

    for (let i=0; i<sprints3.length; i++) {
      let filteredJiraTasks = filterJiraTask(sprints3[i].SprintTasks, jiraTasks)
      let stat = {
        tasksTotal: sprints3[i].SprintTasks.length,
        todo2work: {
          0: 0,
          1: 0,
          2: 0,
          3: 0
        },
        work2done: {
          0: 0,
          1: 0,
          2: 0,
          3: 0
        }
      }

      // history of all changes in issue
      for (let j = 0; j < filteredJiraTasks.length; j++) {
        let hist = await getHistory(auth, id, filteredJiraTasks[j].key)

        let todo2progress = hist
          .issueHistory
          .values
          .filter(el => el.items.length && el.items.find(s => s.field === 'status' && s['fromString'] === 'To Do' && s['toString'] === 'In Progress'))
        let progress2done = hist
          .issueHistory
          .values
          .filter(el => el.items.length && el.items.find(s => s.field === 'status' && s['fromString'] === 'In Progress' && s['toString'] === 'Done'))

        if (todo2progress.length) {
          let period = (new Date(todo2progress[0].created) - new Date(filteredJiraTasks[j].fields.created))/(1000 * 60 * 60)
          stat.todo2work[`${_returnPeriod(period)}`] += 1
        }

        if (progress2done.length) {
          let period = (new Date(progress2done[progress2done.length - 1].created) - new Date(todo2progress[0].created))/(1000 * 60 * 60)
          stat.work2done[`${_returnPeriod(period)}`] += 1
        }
      }

      sprints3[i].stats = {...stat}

    }

    return sprints3
  }

  return { calcProgress, sprintStatus, sprintDateDiff, team, filterJiraTask, computeSprints, getRecommendations, computeDiff3Sprints }
}