import React, { useState, useEffect } from 'react'
import { useHttp } from '../../../hooks/http.hook'
import { useMessage } from '../../../hooks/message.hook'
import { useSelector } from 'react-redux'
import { Offcanvas } from 'react-bootstrap'

export const BlockForm = ({ options, updateOnChange, onClose }) => {
  const auth = useSelector(state => state.auth)
  const { request, error, clearError } = useHttp()
  const message = useMessage()
  const [block, setBlock] = useState({
    id: -new Date(),
    projectId: options.project.id,
    name: '',
    type: 'Block',
    difficulty: 'Easy',
    description: ''
  })

  let newBlock = {
    id: -new Date(),
    projectId: options.project.id,
    name: '',
    type: 'Block',
    difficulty: 'Easy',
    description: ''
  }

  useEffect(() => {
    message(error)
    clearError()
  }, [error, message, clearError])

  useEffect(() => {
    if (options.blockId < 0) {
      setBlock({ ...newBlock })
    } else {
      setBlock({ ...options.project.blocks.find(b => b.id === options.blockId) })
    }
  }, [options])

  const changeBlockHandler = event => {
    setBlock({ ...block, [event.target.name]: event.target.value })
  }

  const validate = () => {
    let ok = true

    if (!block.name) {
      message('Необходимо указать имя блока!')
      ok = false
    }

    return ok
  }

  const updateHandler = async () => {
    if (!validate()) return

    if (block.id < 0) {
      // create
      try {
        const data = await request('/api/block/create', 'POST', { block }, { authorization: 'Bearer ' + auth.token })
        message(data.message)
      } catch (e) {
        console.log(e)
      }

    } else {
      // upadte
      try {
        // update block
        const data = await request(`/api/block/${block.id}`, 'PUT', { block }, { authorization: 'Bearer ' + auth.token })
        message(data.message)
      } catch (e) {
        console.log(e)
      }
    }

    updateOnChange()
  }

  return (
    <Offcanvas show={options.show} onHide={onClose} placement={'end'}>
      <Offcanvas.Header closeButton>
        {/*<Offcanvas.Title><span style={{fontSize: '24px'}}>Создание/Редактирование блока</span></Offcanvas.Title>*/}
        <Offcanvas.Title>Создание/Редактирование блока</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>

        {/*BLOCK*/}
        <div className="row">
          <div className="col-12">

            {/*Name*/}
            <div className="row mb-3">
              {/*label*/}
              <div className=" col-4 align-self-center">
                <span>Название:</span>
              </div>
              {/*field*/}
              <div className="col-8">
                <div className="input-field">
                  <input
                    type="text"
                    id="name"
                    className="form-control"
                    name="name"
                    value={block.name}
                    onChange={changeBlockHandler}
                  />
                </div>
              </div>
            </div>

            {/*Description*/}
            <div className="row mb-3">
              {/*label*/}
              <div className=" col-4 align-self-center">
                <span>Описание:</span>
              </div>
              {/*field*/}
              <div className="col-8">
                <div className="input-field">
                    <textarea
                      id="description"
                      name="description"
                      value={block.description}
                      onChange={changeBlockHandler}
                      className="form-control" />
                </div>
              </div>
            </div>

            {/*Type*/}
            <div className="row mb-3">
              {/*label*/}
              <div className=" col-4 align-self-center">
                <span>Тип:</span>
              </div>
              {/*field*/}
              <div className="col-8">
                <div className="input-field">
                  <select
                    id="type"
                    name="type"
                    value={block.type}
                    className="form-select"
                    onChange={changeBlockHandler}
                  >
                    <option value="Block">Block</option>
                    <option value="Action">Action</option>
                  </select>
                </div>
              </div>
            </div>

            {/*Difficulty*/}
            <div className="row mb-4">
              {/*label*/}
              <div className=" col-4 align-self-center">
                <span>Сложность:</span>
              </div>
              {/*field*/}
              <div className="col-8">
                <div className="input-field">
                  <select
                    id="difficulty"
                    name="difficulty"
                    value={block.difficulty}
                    className="form-select"
                    onChange={changeBlockHandler}
                  >
                    <option value="Easy">Easy</option>
                    <option value="Medium">Medium</option>
                    <option value="Hard">Hard</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <button className="btn btn-bg-blue w-100" onClick={updateHandler}>Сохранить</button>
              </div>
            </div>
          </div>
        </div>

      </Offcanvas.Body>
    </Offcanvas>
  )
}
