import React, { useCallback, useEffect, useState } from 'react'
import { Loader } from '../../partials/Loader'
import { useHttp } from '../../../hooks/http.hook'
import { useMessage } from '../../../hooks/message.hook'
import { useSelector } from 'react-redux'
import { InlineLoader } from '../../partials/InlineLoader'
import { uuidv4 } from '../../partials/uuid'

export const ProjectIntegrationPage = () => {
  const auth = useSelector(state => state.auth)
  const { loading, request, error, clearError } = useHttp()
  const id = useSelector(state => state.sidebar.projectId)
  const [project, setProject] = useState(null)
  const message = useMessage()
  const [form, setForm] = useState({
    jiraUrl: '',
    jiraName: '',
    jiraPass: '',
    jiraProject: '',
    jiraIssues: '',
    jiraSupport: '',
    gitToken: '',
    gitRepo: '',
    gitMaster: '',
    telegramToken: '',
  })
  const [jiraProjects, setJiraProjects] = useState([])
  const [jiraConnected, setJiraConnected] = useState(false)
  const [jiraAlreadyConnected, setJiraAlreadyConnected] = useState(false)

  const [gitRepos, setGitRepos] = useState([])
  const [gitLogin, setGitLogin] = useState('')
  const [gitBranches, setGitBranches] = useState([])
  const [gitConnected, setGitConnected] = useState(false)
  const [gitAlreadyConnected, setGitAlreadyConnected] = useState(false)

  const [telegramConnected, setTelegramConnected] = useState(false)
  const [telegramAlreadyConnected, setTelegramAlreadyConnected] = useState(false)

  useEffect(() => {
    message(error)
    clearError()
  }, [error, message, clearError])

  const checkAlreadyConnected = (project) => {
    // Jira project integration completed
    let jiraConn = project.jiraUrl && project.jiraName && project.jiraPass && project.jiraProject && project.jiraIssues && project.jiraSupport
    if (jiraConn) {
      setJiraAlreadyConnected(true)
    }

    // Git project integration completed
    if (project.gitToken && project.gitRepo && project.gitMaster) {
      setGitAlreadyConnected(true)
    }

    // Telegram project integration completed
    if (project.telegramToken) {
      setTelegramAlreadyConnected(true)
    }
  }

  const getProject = useCallback(async (token, id) => {
    try {
      if (token && id) {
        const data = await request(`/api/project/${id}`, 'GET', null, { authorization: 'Bearer ' + token })
        setProject(data.project)
        setForm({
          jiraUrl: data.project.jiraUrl || '',
          jiraName: data.project.jiraName || '',
          jiraPass: data.project.jiraPass || '',
          jiraProject: data.project.jiraProject || '',
          jiraIssues: data.project.jiraIssues || '',
          jiraSupport: data.project.jiraSupport || '',
          gitToken: data.project.gitToken || '',
          gitRepo: data.project.gitRepo || '',
          gitMaster: data.project.gitMaster || '',
          telegramToken: data.project.telegramToken || uuidv4(),
        })

        checkAlreadyConnected(data.project)

      }
    } catch (e) {
      console.log(e)
    }
  }, [request])

  // get jira
  const getJiraProjects = useCallback(async (token, form) => {
    try {
      const data = await request(`/api/jira/allprojects`, 'POST', { ...form }, { authorization: 'Bearer ' + token })

      if (data.connected && data.projects.length) {
        setJiraProjects(data.projects)
        if (!form.jiraProject) {
          setForm(prev => {return { ...prev, jiraProject: data.projects[0].key }})
        }

        if (!form.jiraIssues) {
          setForm(prev => {return { ...prev, jiraIssues: data.projects[0].key }})
        }

        if (!form.jiraSupport) {
          setForm(prev => {return { ...prev, jiraSupport: data.projects[0].key }})
        }

        setJiraConnected(true)
      } else {
        message('В Jira пока нет проектов')
      }

      message(data.message)

    } catch (e) {
      console.log(e)
    }
  }, [request, message])

  // get git
  const getGitData = useCallback(async (token, form) => {
    try {
      const {
        connected,
        login,
        repos,
        message: msg
      } = await request(`/api/git/repos`, 'POST', { gitToken: form.gitToken }, { authorization: 'Bearer ' + token })

      setGitLogin(login)

      if (connected && repos.length) {
        setGitRepos(repos)
        if (!form.gitRepo) {
          setForm(prev => {return { ...prev, gitRepo: repos[0].name }})
        }

        if (!form.gitMaster) {
          await changeRepo(login, repos[0].name, token, form)
        } else {
          await changeRepo(login, form.gitRepo, token, form)
        }

        setGitConnected(true)
      } else {
        message('В Github пока нет репозиториев')
      }

      message(msg)

    } catch (e) {
      console.log(e)
    }
  }, [request, message])

  // on change Git Repo, reload branches and set branches[0] as default.
  const changeRepo = useCallback(async (login, repoName, token, form) => {
    try {
      const { branches } = await request(`/api/git/branches`, 'POST', {
        gitToken: form.gitToken,
        login,
        repo: repoName
      }, { authorization: 'Bearer ' + token })
      setGitBranches(branches)
      if (branches.length) {
        if (!form.gitMaster || repoName !== form.gitRepo) {
          setForm(prev => {return { ...prev, gitMaster: branches[0].name }})
        }
      } else {
        message('В этом репозитории ещё нет веток')
      }
    } catch (e) {
      console.log(e)
    }
  }, [request, message])

  // for getProject
  useEffect(() => {
    (async () => {
      await getProject(auth.token, id)
    })()
  }, [getProject, id, auth.token])

  // FORM
  const changeHandler = async event => {
    setForm(prev => { return { ...prev, [event.target.name]: event.target.value } })
    if (event.target.name === 'gitRepo') {
      // reload branches and set branches[0]
      await changeRepo(gitLogin, event.target.value, auth.token, form)
    }
  }

  // FORM => PROJECT
  const updateHandler = async () => {
    try {
      const data = await request(`/api/project/${id}`, 'PUT', { form }, { authorization: 'Bearer ' + auth.token })
      message(data.message)
      checkAlreadyConnected(data.project)
    } catch (e) {}
  }

  // JIRA
  useEffect(() => {
    (async () => {
      if (jiraAlreadyConnected) await connectJiraHandler()
    })()
  }, [jiraAlreadyConnected])

  const connectJiraHandler = async () => {
    try {
      await getJiraProjects(auth.token, form)
    } catch (e) {
      console.log(e)
    }
  }

  const disconnectJiraHandler = async () => {
    try {
      // remove jira params from project
      await request(`/api/project/${id}`, 'PUT', {
        form: {
          ...form,
          jiraUrl: '',
          jiraName: '',
          jiraPass: '',
          jiraProject: '',
          jiraIssues: '',
          jiraSupport: ''
        }
      }, { authorization: 'Bearer ' + auth.token })
      setForm(prev => ({
        ...prev,
        jiraUrl: '',
        jiraName: '',
        jiraPass: '',
        jiraProject: '',
        jiraIssues: '',
        jiraSupport: ''
      }))
      setJiraAlreadyConnected(false)
      setJiraConnected(false)
      setJiraProjects([])
    } catch (e) {
      console.log(e)
    }
  }

  // GIT
  useEffect(() => {
    (async () => {
      if (gitAlreadyConnected) await connectGitHandler()
    })()
  }, [gitAlreadyConnected])

  const connectGitHandler = async () => {
    await getGitData(auth.token, form)
  }

  const disconnectGitHandler = async () => {
    try {
      // remove Git params from project
      await request(`/api/project/${id}`, 'PUT', {
        form: {
          ...form,
          gitToken: '',
          gitRepo: '',
          gitMaster: ''
        }
      }, { authorization: 'Bearer ' + auth.token })
      setForm(prev => ({ ...prev, gitToken: '', gitRepo: '', gitMaster: '' }))
      setGitAlreadyConnected(false)
      setGitConnected(false)
      setGitLogin('')
      setGitBranches([])
      setGitRepos([])
    } catch (e) {
      console.log(e)
    }
  }

  // TELEGRAM
  const connectTelegramHandler = async () => {
    await updateHandler()
  }

  const disconnectTelegramHandler = async () => {
    try {
      // todo remove Telegram params from project
    } catch (e) {
      console.log(e)
    }
  }

  if (!project) {
    return <Loader/>
  }

  return (
    <>
      <div className="row flex-column mb-3">
        <div className="col">
          <h5 className="mb-0">Интеграции проекта</h5>
          <p className="text-gray">Настройте связь с инструментами, которыми вы пользуетесь</p>
        </div>
        <div className="col-5">
          <hr style={{ display: 'block', border: '0', borderTop: '1px solid #ced4da' }}/>
        </div>
      </div>

      {/*JIRA*/}
      <>
        <div className="row flex-column">

          <div className="col">
            <h5 className="mb-0" style={{ fontSize: '18px' }}>Интеграция с Jira {loading &&
            <InlineLoader size={20} ml={1}/>}</h5>
            <p
              className="text-gray">{!jiraConnected ? 'Настройте связь с инструментами, которыми вы пользуетесь' : 'Настройте параметры подключаемых проектов'}</p>
          </div>

          {!jiraConnected ?
            // Connect login+pass
            <div className="col-4">
              <div className="input-field mb-3">
                <input
                  placeholder="Server URL"
                  type="text"
                  id="url"
                  className="form-control"
                  name="jiraUrl"
                  value={form.jiraUrl}
                  onChange={changeHandler}
                />
              </div>

              <div className="input-field mb-3">
                <input
                  placeholder="Имя пользователя"
                  type="text"
                  id="username"
                  className="form-control"
                  name="jiraName"
                  value={form.jiraName}
                  onChange={changeHandler}
                />
              </div>

              <div className="input-field mb-3">
                <input
                  placeholder="Пароль"
                  type="password"
                  id="password"
                  className="form-control"
                  name="jiraPass"
                  value={form.jiraPass}
                  onChange={changeHandler}
                />
              </div>

              {/*Test connection*/}
              <button
                className="btn btn-outline-gray w-100"
                onClick={connectJiraHandler}
                disabled={loading}
              >
                Подключить
              </button>
            </div>
            :
            // Select Jira projects
            <>
              <div className="col-6">
                {/*Jira Project*/}
                <div className="row mb-3">
                  {/*Label*/}
                  <div className="col-6 align-self-center">
                    <span>Выберите проект разработки:</span>
                  </div>

                  {/*Select*/}
                  <div className="col-6">
                    <div className="input-field">
                      <select
                        id="jiraProject"
                        name="jiraProject"
                        className="form-select"
                        value={form.jiraProject}
                        onChange={changeHandler}
                      >
                        {jiraProjects.length ? jiraProjects.map(p => <option key={p.id}
                                                                             value={p.key}>{p.name}</option>) : (
                          <option value="0">-</option>)}
                      </select>
                    </div>
                  </div>
                </div>

                {/*Jira Issues*/}
                <div className="row mb-3">
                  {/*Label*/}
                  <div className="col-6 align-self-center">
                    <span>Выберите проект для Issues:</span>
                  </div>

                  {/*Select Issues*/}
                  <div className="col-6">
                    <div className="input-field">
                      <select
                        id="jiraIssues"
                        name="jiraIssues"
                        className="form-select"
                        value={form.jiraIssues}
                        onChange={changeHandler}
                      >
                        {jiraProjects.length ? jiraProjects.map(p => <option key={p.id}
                                                                             value={p.key}>{p.name}</option>) : (
                          <option value="0">-</option>)}
                      </select>
                    </div>
                  </div>
                </div>

                {/*Jira Support*/}
                <div className="row mb-3">
                  {/*Label*/}
                  <div className="col-6 align-self-center">
                    <span>Выберите проект для Support:</span>
                  </div>

                  {/*Select Support*/}
                  <div className="col-6">
                    <div className="input-field">
                      <select
                        id="jiraSupport"
                        name="jiraSupport"
                        className="form-select"
                        value={form.jiraSupport}
                        onChange={changeHandler}
                      >
                        {jiraProjects.length ? jiraProjects.map(p => <option key={p.id}
                                                                             value={p.key}>{p.name}</option>) : (
                          <option value="0">-</option>)}
                      </select>
                    </div>
                  </div>
                </div>

              </div>

              {/*Save / Cancel changes*/}
              <div className="col-6">
                <div className="row">
                  <div className="col d-flex justify-content-start">

                    <button
                      className="btn btn-outline-my mb-3"
                      onClick={updateHandler}
                      disabled={loading || !jiraConnected}
                    >
                      {jiraAlreadyConnected ? `${form.jiraUrl} ✓` : 'Подключить'}
                    </button>

                    <button
                      className="btn btn-outline-gray mb-3 ms-2"
                      onClick={disconnectJiraHandler}
                      disabled={loading || !jiraConnected}
                    >
                      Отключить
                    </button>

                  </div>
                </div>
              </div>
            </>
          }
        </div>
      </>

      {/*hr*/}
      <div className="row my-3">
        <div className="col-5">
          <hr style={{ display: 'block', border: '0', borderTop: '1px solid #ced4da' }}/>
        </div>
      </div>

      {/*Git*/}
      <>
        <div className="row flex-column">
          <div className="col-6">
            <h5 className="mb-0" style={{ fontSize: '18px' }}>Интеграция с Github {loading &&
            <InlineLoader size={20} ml={1}/>}</h5>
            <p className="text-gray">Настройте параметры системы контроля версий и CI/CD</p>
          </div>
        </div>

        {!gitConnected ?
          <>
            <div className="row">
              <div className="col-4">
                <div className="input-field mb-3">
                  <input
                    placeholder="Авторизационный token"
                    type="text"
                    id="gitToken"
                    className="form-control"
                    name="gitToken"
                    value={form.gitToken}
                    onChange={changeHandler}
                  />
                </div>

                {/*Connect*/}
                <button
                  className="btn btn-outline-my w-100"
                  onClick={connectGitHandler}
                  disabled={loading}
                >
                  Подключить Git
                </button>
              </div>
            </div>
          </>
          :
          <>
            <div className="col-6">
              {/*Git repo*/}
              <div className="row mb-3">
                {/*Label*/}
                <div className="col-6 align-self-center">
                  <span>Выберите репозиторий:</span>
                </div>

                {/*Select*/}
                <div className="col-6">
                  <div className="input-field">
                    <select
                      id="gitRepo"
                      name="gitRepo"
                      className="form-select"
                      value={form.gitRepo}
                      onChange={changeHandler}
                    >
                      {gitRepos.length ? gitRepos.map(p => <option key={p.id} value={p.name}>{p.name}</option>) : (
                        <option value="0">-</option>)}
                    </select>
                  </div>
                </div>
              </div>

              {/*Git branch*/}
              <div className="row mb-3">
                {/*Label*/}
                <div className="col-6 align-self-center">
                  <span>Выберите основную ветку:</span>
                </div>

                {/*Select*/}
                <div className="col-6">
                  <div className="input-field">
                    <select
                      id="gitMaster"
                      name="gitMaster"
                      className="form-select"
                      value={form.gitMaster}
                      onChange={changeHandler}
                    >
                      {gitBranches.length ? gitBranches.map(p => <option key={p.name}
                                                                         value={p.name}>{p.name}</option>) : (
                        <option value="0">-</option>)}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            {/*Save / Cancel changes*/}
            <div className="col-6">
              <div className="row">
                <div className="col d-flex justify-content-start">

                  <button
                    className="btn btn-outline-my mb-3"
                    onClick={updateHandler}
                    disabled={loading || !gitConnected}
                  >
                    {gitAlreadyConnected ? `Github подключен ✓` : 'Подключить'}
                  </button>

                  <button
                    className="btn btn-outline-gray mb-3 ms-2"
                    onClick={disconnectGitHandler}
                    disabled={loading || !gitConnected}
                  >
                    Отключить
                  </button>

                </div>
              </div>
            </div>
          </>
        }
      </>

      {/*hr*/}
      <div className="row my-3">
        <div className="col-5">
          <hr style={{ display: 'block', border: '0', borderTop: '1px solid #ced4da' }}/>
        </div>
      </div>

      {/*Telegram*/}
      <>
        <div className="row flex-column mb-3">
          <div className="col-6">
            <h5 className="mb-0" style={{ fontSize: '18px' }}>Интеграция с Telegram {loading &&
            <InlineLoader size={20} ml={1}/>}</h5>
            <p className="text-gray">Для настройки интеграции вам необходимо добавить bot @itwork_bot к себе в телеграмм
              и ввести в него авторизационный токен указанный ниже</p>
          </div>

          <div className="row">
            <div className="col-4">
              <div className="input-field mb-3">
                <input
                  placeholder="Авторизационный token"
                  type="text"
                  id="username"
                  className="form-control"
                  name="telegramToken"
                  value={form.telegramToken}
                  onChange={changeHandler}
                />
              </div>

              {/*Connect*/}
              <button
                className="btn btn-outline-my w-100"
                onClick={connectTelegramHandler}
                disabled={loading}
              >
                Подключить Telegram
              </button>

            </div>
          </div>
        </div>
      </>
    </>
  )

}
