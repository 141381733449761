export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'

export const SET_MODE_PROJECT = 'SET_MODE_PROJECT'
export const SET_MODE_PROFILE = 'SET_MODE_PROFILE'
export const SET_PROJECT_ID = 'SET_PROJECT_ID'

export const SET_NAVBAR_ITEMS = 'SET_NAVBAR_ITEMS'
export const SET_NAVBAR_SUBITEMS = 'SET_NAVBAR_SUBITEMS'
export const SET_NAVBAR_ITEMS_ACTIVE = 'SET_NAVBAR_ITEMS_ACTIVE'
export const SET_NAVBAR_SUBITEMS_ACTIVE = 'SET_NAVBAR_SUBITEMS_ACTIVE'
export const SET_NAVBAR_SHOW_SUB = 'SET_NAVBAR_SHOW_SUB'
export const SET_NAVBAR_HIDE_SUB = 'SET_NAVBAR_HIDE_SUB'
export const SET_NAVBAR_BUDGE = 'SET_NAVBAR_BUDGE'