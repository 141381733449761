import React, { useState, useEffect } from 'react'
import { useHttp } from '../../../hooks/http.hook'
import { useMessage } from '../../../hooks/message.hook'
import { useSelector } from 'react-redux'
import { Offcanvas } from 'react-bootstrap'


export const TeamForm = ({ options, updateOnChange, onClose }) => {
  const auth = useSelector(state => state.auth)
  const { request, error, clearError } = useHttp()
  const message = useMessage()
  const [user, setUser] = useState({
    id: -new Date(),
    projectId: options.projectId,
    name: '',
    email: '',
    password: '',
    role: '',
    jiraUser: '',
  })

  let newUser = {
    id: -new Date(),
    projectId: options.projectId,
    name: '',
    email: '',
    password: '',
    role: '',
    jiraUser: '',
  }

  useEffect(() => {
    message(error)
    clearError()
  }, [error, message, clearError])

  useEffect(() => {
    if (options.userId < 0) {
      setUser({ ...newUser })
    } else {
      setUser({ ...options.team.find(u => u.id === options.userId) })
    }
  }, [options])

  const changeBlockHandler = event => {
    setUser({ ...user, [event.target.name]: event.target.value })
  }

  const validate = () => {
    let ok = true

    if (!user.name) {
      message('Необходимо указать имя!')
      ok = false
    }

    return ok
  }

  const updateHandler = async () => {
    if (!validate()) return

    if (user.id < 0) {
      // create
      try {
        const data = await request('/api/team/create', 'POST', { user }, { authorization: 'Bearer ' + auth.token })
        message(data.message)
      } catch (e) {
        console.log(e)
      }

    } else {
      // upadte
      try {
        // update
        const data = await request(`/api/team/${user.id}`, 'PUT', { user }, { authorization: 'Bearer ' + auth.token })
        message(data.message)
      } catch (e) {
        console.log(e)
      }
    }

    updateOnChange()
  }

  return (
    <Offcanvas show={options.show} onHide={onClose} placement={'end'}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Создание/Редактирование участника</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>

        {/*User*/}
        <div className="row">
          <div className="col-12">

            {/*Name*/}
            <div className="row mb-3">
              {/*label*/}
              <div className=" col-4 align-self-center">
                <span>Имя:</span>
              </div>
              {/*field*/}
              <div className="col-8">
                <div className="input-field">
                  <input
                    type="text"
                    id="name"
                    className="form-control"
                    name="name"
                    value={user.name}
                    onChange={changeBlockHandler}
                  />
                </div>
              </div>
            </div>

            {/*Email*/}
            <div className="row mb-3">
              {/*label*/}
              <div className=" col-4 align-self-center">
                <span>Email:</span>
              </div>
              {/*field*/}
              <div className="col-8">
                <div className="input-field">
                  <div className="input-field">
                    <input
                      type="text"
                      id="email"
                      className="form-control"
                      name="email"
                      value={user.email}
                      onChange={changeBlockHandler}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/*Role*/}
            <div className="row mb-3">
              {/*label*/}
              <div className=" col-4 align-self-center">
                <span>Роль:</span>
              </div>
              {/*field*/}
              <div className="col-8">
                <div className="input-field">
                  <input
                    type="text"
                    id="role"
                    className="form-control"
                    name="role"
                    value={user.role}
                    onChange={changeBlockHandler}
                  />
                </div>
              </div>
            </div>

            {/*Password*/}
            <div className="row mb-3">
              {/*label*/}
              <div className=" col-4 align-self-center">
                <span>Пароль:</span>
              </div>
              {/*field*/}
              <div className="col-8">
                <div className="input-field">
                  <input
                    type="password"
                    id="password"
                    className="form-control"
                    name="password"
                    value={user.password}
                    onChange={changeBlockHandler}
                  />
                </div>
              </div>
            </div>

            {/*Связка Jira*/}
            <div className="row mb-4">
              {/*label*/}
              <div className=" col-4 align-self-center">
                <span>Связка Jira:</span>
              </div>
              {/*field*/}
              <div className="col-8">
                <div className="input-field">
                  <select
                    id="jiraUser"
                    name="jiraUser"
                    value={user.jiraUser}
                    className="form-select"
                    onChange={changeBlockHandler}
                  >
                    {/*<option value="Easy">Easy</option>*/}
                    { options.jiraUsers.length ? options.jiraUsers.map((p, idx) => <option key={idx} value={p.emailAddress}>{p.emailAddress}</option>) : (<option value="0">-</option>)}
                  </select>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <button className="btn btn-bg-blue w-100" onClick={updateHandler}>Сохранить</button>
              </div>
            </div>
          </div>
        </div>

      </Offcanvas.Body>
    </Offcanvas>
  )
}
