import React, { useCallback, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useMessage } from '../../../hooks/message.hook'
import { useHttp } from '../../../hooks/http.hook'
import { Loader } from '../../partials/Loader'
import { NoTable } from '../../partials/NoTable'
import { useDispatch, useSelector } from 'react-redux'
import { useJira } from '../../../hooks/jira.hook'
import { setActiveItem, setActiveSubItem, showSubMenu } from '../../../redux/actions/navbar.actions'
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { Icon } from '../../partials/Icon'

export const RoadmapPage = () => {
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const id = useSelector(state => state.sidebar.projectId)
  const history = useHistory()
  const { loading, request, error, clearError, silentRequest } = useHttp()
  const message = useMessage()
  const { getJiraEpics, jiraLoading, jiraConnected, calcEpicProgress } = useJira()
  const [project, setProject] = useState(null)
  const [jiraStatuses, setJiraStatuses] = useState([])
  const [jiraEpics, setJiraEpics] = useState([])


  useEffect(() => {
    message(error)
    clearError()
  }, [error, message, clearError, history])

  const getProject = useCallback(async (token, id) => {
    try {
      if (token && id) {
        const data = await request(`/api/project/blocks/${id}`, 'GET', null, { authorization: 'Bearer ' + token })
        setProject(data.project)

        // Get jira
        const jira = await getJiraEpics(auth, id, 'dev')

        if (jira.message) message(jira.message)
        setJiraStatuses(jira.jiraStatuses)
        setJiraEpics(jira.jiraEpics)
      }
    } catch (e) {
      console.log(e)
    }
  }, [request])

  const createEpicsInJira = useCallback(async (epics = [], token, id) => {
    // create epics in Jira (epics.name, epics.description)
    await request(`/api/jira/createEpics`, 'POST', {
      jiraName: project.jiraName,
      jiraUrl: project.jiraUrl,
      jiraPass: project.jiraPass,
      jiraProjectName: project.jiraProject,
      epics
    }, { authorization: 'Bearer ' + token })

    await getProject(token, id)

  }, [request, getProject, project])

  const getBlocksToCreateEpics = useCallback(async (token, id) => {
    try {
      if (project && project.blocks.length && project.archStr) {
        // Get Arch elements
        let loadedEls = JSON.parse(project.archStr)

        // console.log('loadedEls before', loadedEls)

        // get only not deleted blocks
        loadedEls = loadedEls.map(el => {
          if (el.id[0] === 'd') {
            let pBlock = project.blocks.find(b => b.id === el.data.b.id)
            if (pBlock) {
              // el.data.b = {...pBlock}
              // return el.data.b.id
              return pBlock.id
            }
          }
        }).filter(el => el !== undefined)

        // Get unique id of used blocks
        // loadedEls = [...new Set(loadedEls.map(el => el.id))]
        loadedEls = [...new Set(loadedEls)]

        // get really blocks to create epics
        let epicsToCreate = []
        loadedEls.forEach(el => {
          epicsToCreate.push(project.blocks.find(b => b.id === el))
        })

        // add Git branch to Epics labels
        epicsToCreate = epicsToCreate.map(el => ({ ...el, gitBranch: el.name.toLowerCase().replaceAll(' ', '') }))

        // console.log('loadedEls after', loadedEls)
        // console.log('epicsToCreate', epicsToCreate)

        // create epics in Jira (epicsToCreate.name, epicsToCreate.description)
        await createEpicsInJira(epicsToCreate, token, id)

        // create branches for in Git for Epics
        for (let i = 0; i < epicsToCreate.length; i++) {
          await createBranch(epicsToCreate[i].gitBranch)
        }
      }
    } catch (e) {
      console.log(e)
    }
  }, [project, auth.token, id])

  const deleteEpic = async (eId) => {
    try {
      if (project) {
        await silentRequest(`/api/jira/deleteEpic`, 'POST', {
          jiraName: project.jiraName,
          jiraUrl: project.jiraUrl,
          jiraPass: project.jiraPass,
          epicId: eId
        }, { authorization: 'Bearer ' + auth.token })

        if (jiraEpics.find(el => el.id === eId).labels.length) {
          await deleteBranch(jiraEpics.find(el => el.id === eId).labels[0])
        }
        await getProject(auth.token, id)
      }
    } catch (e) {
      console.log(e)
    }
  }

  // const calcProgress = (epic) => {
  //   const progress = Math.floor(epic.tasks.filter(ts => ts.status.name === 'Готово').length * 100 / epic.tasks.length)
  //   return !isNaN(progress) ? progress : 0
  // }

  const createBranch = async (branch) => {
    try {
      if (project) {
        await silentRequest(`/api/git/createBranch`, 'POST', {
          repo: project.gitRepo,
          gitToken: project.gitToken,
          mainBranch: project.gitMaster,
          branch
        }, { authorization: 'Bearer ' + auth.token })
      }
    } catch (e) {
      console.log(e)
    }
  }

  const deleteBranch = async (branch) => {
    try {
      if (project) {
        await silentRequest(`/api/git/deleteBranch`, 'POST', {
          repo: project.gitRepo,
          gitToken: project.gitToken,
          branch
        }, { authorization: 'Bearer ' + auth.token })
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    (async () => {
      await getProject(auth.token, id)
    })()

  }, [getProject, id, auth.token])

  if (!project || loading) {
    return <Loader/>
  }

  // todo: check git integration (and show message)
  return (
    <>
      {!jiraLoading && !jiraConnected ?
        <div className="row justify-content-center">
          <div className="col-12">
            <div style={{
              backgroundColor: '#fff4cd',
              color: '#866607',
              padding: '10px',
              textAlign: 'justify',
              marginBottom: '3rem'
            }}>
              <span style={{ fontWeight: 600 }}>Важно!</span> У вас не настроена интеграция с Jira.
              Вы можете настроить интеграцию <Link to={`#`} onClick={() => {
              dispatch(setActiveItem('/settings'))
              dispatch(setActiveSubItem('/settings/integration'))
              dispatch(showSubMenu())
              history.push(`/settings/integration`)
            }}>здесь.</Link>
            </div>
          </div>
        </div>
        : <>
          <div className="row justify-content-between mb-3">
            <div className="col">

              <h5 className="mb-0">Roadmap</h5>
              <p className="text-gray">Следите за реализацией архитектурных модулей</p>
            </div>

            <div className="col align-self-end text-end">
              <button
                className="btn btn-outline-my mb16px"
                type="button"
                onClick={() => {
                  // jiraEpics.forEach(e => {
                  //     console.log('epic id', e.id)
                  //     deleteEpic(e.id)
                  //   }
                  // )
                  getBlocksToCreateEpics(auth.token, id)
                }}
              >Создать roadmap
              </button>
            </div>
          </div>

          <div className="row mb-3 h-100">

            {!jiraLoading && jiraEpics && jiraEpics.length ? (
              <div className="col-12">
                <table className="table">
                  <thead>
                  <tr>
                    <th className="text-gray"/>
                    <th className="text-gray"/>
                    <th className="text-gray"/>
                    <th className="text-gray"/>
                    <th className="text-gray"/>
                  </tr>
                  </thead>
                  <tbody className="table-hover">
                  {jiraEpics.map(epic => {
                    return (
                      <tr
                        key={epic.id}
                      >
                        {/*<td className="py-0" style={{ width: '22px' }}></td>*/}
                        <td>
                          <div className="row">
                            <div className="col-auto">
                              <div
                                className="d-flex justify-content-center"
                                style={{
                                  width: '40px',
                                  height: '40px',
                                  border: !calcEpicProgress(epic) ? '1px solid rgba(0,0,0, .15)' : '1px solid #6A4EF4',
                                  borderRadius: '50%'
                                }}
                              >
                                <div className="align-self-center" style={{ width: '85%', height: '85%' }}>
                                  <CircularProgressbarWithChildren
                                    strokeWidth={50}
                                    styles={buildStyles({
                                      strokeLinecap: 'butt',
                                      pathColor: '#6A4EF4',
                                      trailColor: 'transparent'
                                    })}
                                    value={calcEpicProgress(epic)}
                                  >
                                    {/*<CircularProgressbar*/}
                                    {/*  strokeWidth={2}*/}
                                    {/*  styles={buildStyles({*/}
                                    {/*    pathColor: 'rgba(0,0,0, 0.15)',*/}
                                    {/*    // backgroundColor: '#fff'*/}
                                    {/*  })}*/}
                                    {/*  value={100}*/}
                                    {/*/>*/}
                                  </ CircularProgressbarWithChildren>
                                </div>
                              </div>
                            </div>
                            <div className="col-auto">
                              <p className="mb-0">{epic.summary}</p>
                              <p className="mb-0 text-gray"
                                 style={{ fontSize: '10px' }}>{epic.tasks.length ? `${calcEpicProgress(epic)}% завершено` : `Небходима декомпозиция`}</p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <p
                            className="mb-0">{epic.tasks.length ? `${epic.tasks.filter(ts => ts.status.name === 'Готово').length} / ${epic.tasks.length}` : `Отсутств.`}</p>
                          <p className="mb-0 text-gray" style={{ fontSize: '10px' }}>задач.</p>
                        </td>
                        <td>
                          <p className="mb-0">{project.teams.length ? `${project.teams.length} чел.` : 'Отсутств.'}</p>
                          <p className="mb-0 text-gray" style={{ fontSize: '10px' }}>команда</p>
                        </td>
                        <td>
                          <div className="row justify-content-center align-content-center">
                            <div className="col-auto">
                              <Icon name="Git" size="18px" mr={0}/>
                            </div>
                            <div className="col auto">
                              <p className="mb-0" style={{ fontSize: '12px' }}>{epic.labels.length ? `${project.gitRepo}/${epic.labels[0]}` : 'Отсутств.'}</p>
                              <p className="mb-0 text-gray" style={{ fontSize: '10px' }}>Git branch</p>
                            </div>
                          </div>
                        </td>
                        <td>
                          {/*<i*/}
                          {/*  style={{ cursor: 'pointer' }}*/}
                          {/*  className="fa fa-pencil-square-o opacity-0"*/}
                          {/*  aria-hidden="true"*/}
                          {/*  // onClick={() => {history.push(`/project/${c.projectId}/testCases/${c.id}`)}}*/}
                          {/*  onClick={() => {*/}
                          {/*    createBranch(epic.summary)*/}
                          {/*  }}*/}
                          {/*/>*/}
                          <i
                            style={{ cursor: 'pointer' }}
                            className="fa fa-trash-o ps-2 opacity-0"
                            aria-hidden="true"
                            onClick={() => {deleteEpic(epic.id)}}
                          />
                        </td>
                      </tr>
                    )
                  })}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="col pt-5">
                {jiraLoading ? <Loader size={80}/> : <NoTable params={{ title: `В этом проекте пока нет модулей` }}/>}
              </div>
            )}
          </div>
        </>}
    </>
  )
}
