import React, {useCallback, useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHttp } from '../../../hooks/http.hook'
import { useMessage } from '../../../hooks/message.hook'
import { useHistory } from 'react-router-dom'
import { logout } from '../../../redux/actions/auth.actions'
import { InlineLoader } from '../../partials/InlineLoader'
import { Icon } from '../../partials/Icon'

export const SprintSettingsPage = () => {
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const id = useSelector(state => state.sidebar.projectId)
  const {loading, request, error, clearError} = useHttp()
  const [settings, setSettings] = useState()
  const message = useMessage()
  const history = useHistory()
  const [form, setForm] = useState({
    projectId: id,
    startDay: '1',
    sprintDuration: '1',
    draftForward: '1',

    meetingTime: '',

    requestOpen: '',
    requestHaveComments: '',
    requestMerged: '',
  })
  const [steps, setSteps] = useState([])
  const [showSelectStep, setShowSelectStep] = useState(false)


  useEffect(() => {
    message(error)
    if (error === 'Нет авторизации') {
      clearError()
      dispatch(logout())
      history.push('/')
    }
    clearError()
  }, [error, message, clearError, logout, history])

  const changeHandler = event => {
    setForm({ ...form, [event.target.name]: event.target.value })
  }

  const getSettings = useCallback(async (token, id) => {
    try {
      if (token && id) {
        const data = await request(`/api/sprint/getSettings/${id}`, 'GET', null, { authorization: 'Bearer ' + token })
        if (data.settings) {
          setSettings(data.settings)
          setForm(prev => ({
            ...prev,
            startDay: data.settings.startDay,
            sprintDuration: data.settings.sprintDuration,
            draftForward: data.settings.draftForward,
            meetingTime: data.settings.meetingTime,
            requestOpen: data.settings.requestOpen,
            requestHaveComments: data.settings.requestHaveComments,
            requestMerged: data.settings.requestMerged,
          }))
          let st = JSON.parse(data.settings.steps)
          if (st && st.length) {

          }
          setSteps([...st.map(el => ({ ...el, showAdd:false }))])
        }
      }
    } catch (e) {
      console.log(e)
    }
  }, [request])

  useEffect(() => {
    (async () => {
      await getSettings(auth.token, id)
    })()

  }, [getSettings, id, auth.token])

  const changeSelectStepHandler = (event, sId) => {
    let arr = [...steps]
    console.log('before', arr)
    if (sId >= 0) {
      let idx = arr.findIndex(el => el.id === sId)
      arr[idx] = {...arr[idx], showAdd: false}
      arr.splice(++idx, 0, {id: +new Date(), [event.target.name]: event.target.value, showAdd: false})
    } else {
      arr.push({id: +new Date(), [event.target.name]: event.target.value})
    }

    setSteps([...arr.map(el => ({ ...el, showAdd:false }))])
    console.log('after', arr.map(el => ({ ...el, showAdd:false })))

    setShowSelectStep(false)
  }

  const showSelectStepHandle = sId => {
    let arr = [...steps]
    let idx = arr.findIndex(el => el.id === sId)
    arr[idx] = {...arr[idx], showAdd: true}
    setSteps([...arr])
  }

  const updateHandler = async () => {
    try {
      const data = await request(`/api/sprint/updateSettings/${id}`, 'POST', { settings: {...form, steps: JSON.stringify(steps)} }, { authorization: 'Bearer ' + auth.token })
      message(data.message)
    } catch (e) {
      console.log(e)
    }
  }

  const rmStepHandler = (sId) => {
    setSteps([...steps.filter(el => el.id !== sId)])
  }

  return (
    <>
      {/*Header*/}
      <div className="row">
        <div className="col">
          <h5 className="mb-0" style={{fontSize: '24px'}}>Настройки спринта</h5>
          <div className="text-gray mb16px mb-0">Настройте параметры и этапы спринта {loading && <InlineLoader size={20} ml={1}/>}</div>
        </div>
      </div>

      {/*HR*/}
      <div className="row mt-1 mb-3">
        <div className="col-6">
          <hr style={{display: 'block', border: '0', borderTop: '1px solid #ced4da'}}/>
        </div>
      </div>

      {/*Params*/}
      <div className="row">
        <div className="col-6">
          {/*Header*/}
          <div className="row mb-3">
            <div className="col">
              <h5 style={{fontSize: '18px', fontWeight: 600}}>Параметры спринта</h5>
              <div className="text-gray mb16px mb-0">Настройте параметры подключаемых проектов {loading && <InlineLoader size={20} ml={1}/>}</div>
            </div>
          </div>

          {/*Start sprint*/}
          <div className="row mb-3">
            {/*label*/}
            <div className=" col-6 align-self-center">
              <span>Начало спринта:</span>
            </div>
            {/*field*/}
            <div className="col-6">
              <div className="input-field">
                <select
                  id="startDay"
                  name="startDay"
                  value={form.startDay}
                  className="form-select"
                  onChange={changeHandler}
                >
                  <option value="1">Понедельник</option>
                  <option value="2">Вторник</option>
                  <option value="3">Среда</option>
                  <option value="4">Четверг</option>
                  <option value="5">Пятница</option>
                  <option value="6">Суббота</option>
                  <option value="0">Воскресение</option>
                </select>
              </div>
            </div>
          </div>

          {/*Sprint duration*/}
          <div className="row mb-3">
            {/*label*/}
            <div className=" col-6 align-self-center">
              <span>Продолжительность спринта:</span>
            </div>
            {/*field*/}
            <div className="col-6">
              <div className="input-field">
                <select
                  id="sprintDuration"
                  name="sprintDuration"
                  value={form.sprintDuration}
                  className="form-select"
                  onChange={changeHandler}
                >
                  <option value="1">1 неделя</option>
                  <option value="2">2 недели</option>
                  <option value="3">3 недели</option>
                  <option value="4">4 недели</option>
                </select>
              </div>
            </div>
          </div>

          {/*Draft Sprint forward*/}
          <div className="row mb-3">
            {/*label*/}
            <div className=" col-6 align-self-center">
              <span>Создавать черновик спринта на:</span>
            </div>
            {/*field*/}
            <div className="col-6">
              <div className="input-field">
                <select
                  id="draftForward"
                  name="draftForward"
                  value={form.draftForward}
                  className="form-select"
                  onChange={changeHandler}
                >
                  <option value="1">1 спринт вперёд</option>
                  <option value="2">2 спринта вперёд</option>
                  <option value="3">3 спринта вперёд</option>
                  <option value="4">4 спринта вперёд</option>
                </select>
              </div>
            </div>
          </div>

          {/*Save*/}
          <div className="row">
            <div className="col-6">
              <button className="btn btn-outline-my w-75" onClick={updateHandler}>Сохранить</button>
            </div>
          </div>
        </div>
      </div>

      {/*HR*/}
      <div className="row my-3">
        <div className="col-6">
          <hr style={{display: 'block', border: '0', borderTop: '1px solid #ced4da'}}/>
        </div>
      </div>

      {/*Steps*/}
      <div className="row">
        <div className="col-6">
          {/*Header*/}
          <div className="row mb-3">
            <div className="col">
              <h5 style={{fontSize: '18px', fontWeight: 600}}>Этапы спринта</h5>
              <div className="text-gray mb16px mb-0">Настройте этапы спринта {loading && <InlineLoader size={20} ml={1}/>}</div>
            </div>
          </div>

          {steps.length ? steps.map(step => {return(
              <React.Fragment key={step.id}>
                <div className="row mb-1">
                  <div className="col-12">
                    <p className="mb-1">
                      <Icon name="box" size="18px" mr={8} mt={-4}/> <span>{step.name}</span>
                      <span className="text-gray" style={{cursor: 'pointer', paddingLeft: '6px', fontSize: '20px'}} onClick={() => {rmStepHandler(step.id)}}> &times;</span>
                    </p>
                  </div>
                </div>

                <p className="text-gray" style={{cursor: 'pointer'}} onClick={() => {showSelectStepHandle(step.id)}}>
                  <i className="fa fa-arrow-down" aria-hidden="true" style={{marginLeft: '2px'}}/>Добавить этап</p>

                {step.showAdd ?
                  <React.Fragment key={step.id}>
                    {/*SelectStep*/}
                    <div className="row mb-3">
                      {/*field*/}
                      <div className="col-6">
                        <div className="input-field">
                          <select
                            id="name"
                            name="name"
                            defaultValue='-'
                            className="form-select"
                            onChange={e => {changeSelectStepHandler(e, step.id)}}
                          >
                            <option value="-" disabled>-</option>
                            <option value="Ежедневные митинги">Ежедневные митинги</option>
                            <option value="Мерж реквесты через review">Мерж реквесты через review</option>
                            <option value="Тестирование">Тестирование</option>
                            <option value="Релиз">Релиз</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </React.Fragment> : <></>}
              </React.Fragment>
            )})
            :
            <>
              <p className="text-gray" style={{cursor: 'pointer'}} onClick={() => {setShowSelectStep(true)}}>
                <i className="fa fa-arrow-down" aria-hidden="true" style={{marginLeft: '2px'}}/>Добавить этап</p>

              {showSelectStep ?
              <>
                {/*SelectStep*/}
                <div className="row mb-3">
                  {/*field*/}
                  <div className="col-6">
                    <div className="input-field">
                      <select
                        id="name"
                        name="name"
                        defaultValue='-'
                        className="form-select"
                        onChange={e => {changeSelectStepHandler(e, -1)}}
                      >
                        <option value="-" disabled>-</option>
                        <option value="Ежедневные митинги">Ежедневные митинги</option>
                        <option value="Мерж реквесты через review">Мерж реквесты через review</option>
                        <option value="Тестирование">Тестирование</option>
                        <option value="Релиз">Релиз</option>
                      </select>
                    </div>
                  </div>
                </div>
              </> : <></>}
            </>
          }


          {/*Save*/}
          <div className="row mt-3">
            <div className="col-6">
              <button className="btn btn-outline-my w-75" onClick={updateHandler}>Сохранить</button>
            </div>
          </div>
        </div>
      </div>

      {/*HR*/}
      <div className="row my-3">
        <div className="col-6">
          <hr style={{display: 'block', border: '0', borderTop: '1px solid #ced4da'}}/>
        </div>
      </div>

      {/*Meetings*/}
      <div className="row">
        <div className="col-6">
          {/*Header*/}
          <div className="row mb-3">
            <div className="col">
              <h5 style={{fontSize: '18px', fontWeight: 600}}>Параметры “Ежедневных митингов”</h5>
              <div className="text-gray mb16px mb-0">Настройте параметры ежедневных митингов {loading && <InlineLoader size={20} ml={1}/>}</div>
            </div>
          </div>

          {/*meetingTime*/}
          <div className="row mb-3">
            {/*label*/}
            <div className=" col-6 align-self-center">
              <span>Введите время для ежедневных митингов:</span>
            </div>
            {/*field*/}
            <div className="col-6">
              <div className="input-field">
                <input
                  type="time"
                  id="meetingTime"
                  className="form-control"
                  name="meetingTime"
                  value={form.meetingTime}
                  onChange={changeHandler}
                />
              </div>
            </div>
          </div>

          {/*Save*/}
          <div className="row">
            <div className="col-6">
              <button className="btn btn-outline-my w-75" onClick={updateHandler}>Сохранить</button>
            </div>
          </div>
        </div>
      </div>

      {/*HR*/}
      <div className="row my-3">
        <div className="col-6">
          <hr style={{display: 'block', border: '0', borderTop: '1px solid #ced4da'}}/>
        </div>
      </div>

      {/*Requests*/}
      <div className="row">
        <div className="col-6">
          {/*Header*/}
          <div className="row mb-3">
            <div className="col">
              <h5 style={{fontSize: '18px', fontWeight: 600}}>Параметры Merge Requests</h5>
              <div className="text-gray mb16px mb-0">Настройте параметры Merge Requests {loading && <InlineLoader size={20} ml={1}/>}</div>
            </div>
          </div>

          {/*requestOpen*/}
          <div className="row mb-3">
            {/*label*/}
            <div className=" col-6 align-self-center">
              <span>Когда реквест открыт:</span>
            </div>
            {/*field*/}
            <div className="col-6">
              <div className="input-field">
                <input
                  type="text"
                  id="requestOpen"
                  className="form-control"
                  name="requestOpen"
                  value={form.requestOpen}
                  onChange={changeHandler}
                />
              </div>
            </div>
          </div>

          {/*requestHaveComments*/}
          <div className="row mb-3">
            {/*label*/}
            <div className=" col-6 align-self-center">
              <span>Когда появляются комментарии:</span>
            </div>
            {/*field*/}
            <div className="col-6">
              <div className="input-field">
                <input
                  type="text"
                  id="requestHaveComments"
                  className="form-control"
                  name="requestHaveComments"
                  value={form.requestHaveComments}
                  onChange={changeHandler}
                />
              </div>
            </div>
          </div>

          {/*requestMerged*/}
          <div className="row mb-3">
            {/*label*/}
            <div className=" col-6 align-self-center">
              <span>Когда смержен:</span>
            </div>
            {/*field*/}
            <div className="col-6">
              <div className="input-field">
                <input
                  type="text"
                  id="requestMerged"
                  className="form-control"
                  name="requestMerged"
                  value={form.requestMerged}
                  onChange={changeHandler}
                />
              </div>
            </div>
          </div>

          {/*Save*/}
          <div className="row">
            <div className="col-6">
              <button className="btn btn-outline-my w-75" onClick={updateHandler}>Сохранить</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}