import { useState, useCallback } from 'react'
import { useHttp } from './http.hook'
import { useMessage } from './message.hook'

export const useJira = () => {
  const { loading, request, error, clearError } = useHttp()
  const [jiraLoading, setJiraLoading] = useState(false)
  const [jiraConnected, setJiraConnected] = useState(false)

  // Get jira users
  const getJiraUsers = useCallback(async (auth, id) => {
    try {
      setJiraLoading(true)
      if (auth.user.projects.length && auth.user.projects.find(prj => prj.id === id) && auth.user.projects.find(prj => prj.id === id).jiraPass) {
        const project = auth.user.projects.find(prj => prj.id === id)
        const sk = await request(`/api/jira/allUsers`, 'POST', {
          jiraName: project.jiraName,
          jiraUrl: project.jiraUrl,
          jiraPass: project.jiraPass,
        }, { authorization: 'Bearer ' + auth.token })
        if (sk.connected) {
          setJiraLoading(false)
          setJiraConnected(true)
          return { jiraUsers: sk.users, message: '' }
        }
      } else {
        setJiraLoading(false)
        setJiraConnected(false)
        return { jiraUsers: [], message: 'Jira не подключен' }
      }
    } catch (e) {
      setJiraLoading(false)
      setJiraConnected(false)
      console.log(e)
      return { jiraUsers: [], message: 'Jira не подключен' }
    }
  }, [])

  // Get issue history
  const getHistory = useCallback(async (auth, id, issueNumber) => {
    try {
      setJiraLoading(true)
      if (auth.user.projects.length && auth.user.projects.find(prj => prj.id === id) && auth.user.projects.find(prj => prj.id === id).jiraPass) {
        const project = auth.user.projects.find(prj => prj.id === id)

        const sk = await request(`/api/jira/getHistory`, 'POST', {
          jiraName: project.jiraName,
          jiraUrl: project.jiraUrl,
          jiraPass: project.jiraPass,
          issueNumber
        }, { authorization: 'Bearer ' + auth.token })

        if (sk.connected) {
          setJiraLoading(false)
          setJiraConnected(true)
          return { issueHistory: sk.issueHistory, message: '' }
        }
      } else {
        setJiraLoading(false)
        setJiraConnected(false)
        return { issueHistory: [], message: 'Jira не подключен' }
      }
    } catch (e) {
      setJiraLoading(false)
      setJiraConnected(false)
      console.log(e)
      return { issueHistory: [], message: 'Jira не подключен' }
    }
  }, [])

  // Get jira Epics
  const setEpicStatus = (statuses, epic) => {
    if (epic.tasks.length) {
      if (epic.tasks.filter(ts => ts.status.name === 'В работе').length > 0) {
        epic.status = {...epic.tasks.filter(ts => ts.status.name === 'В работе')[0].status}
        return
      }

      if (epic.tasks.filter(ts => ts.status.name === 'Готово').length === epic.tasks.length) {
        epic.status = {...epic.tasks.filter(ts => ts.status.name === 'Готово')[0].status}
      }
    }
  }

  // get transitions for issue
  const getTransitions = useCallback(async (auth, id, issueNumber) => {
    try {
      setJiraLoading(true)
      if (auth.user.projects.length && auth.user.projects.find(prj => prj.id === id) && auth.user.projects.find(prj => prj.id === id).jiraPass) {
        const project = auth.user.projects.find(prj => prj.id === id)

        const sk = await request(`/api/jira/getTransitions`, 'POST', {
          jiraName: project.jiraName,
          jiraUrl: project.jiraUrl,
          jiraPass: project.jiraPass,
          issueNumber
        }, { authorization: 'Bearer ' + auth.token })

        if (sk.connected) {
          setJiraLoading(false)
          setJiraConnected(true)
          return { issueTransitions: sk.issueTransitions, message: '' }
        }
      } else {
        setJiraLoading(false)
        setJiraConnected(false)
        return { issueTransitions: [], message: 'Jira не подключен' }
      }
    } catch (e) {
      setJiraLoading(false)
      setJiraConnected(false)
      console.log(e)
      return { issueTransitions: [], message: 'Jira не подключен' }
    }
  }, [])

  // make transitions for issue
  const makeTransitions = useCallback(async (auth, id, issueNumber, transitionId) => {
    try {
      setJiraLoading(true)
      if (auth.user.projects.length && auth.user.projects.find(prj => prj.id === id) && auth.user.projects.find(prj => prj.id === id).jiraPass) {
        const project = auth.user.projects.find(prj => prj.id === id)

        const sk = await request(`/api/jira/makeTransitions`, 'POST', {
          jiraName: project.jiraName,
          jiraUrl: project.jiraUrl,
          jiraPass: project.jiraPass,
          issueNumber,
          transitionId
        }, { authorization: 'Bearer ' + auth.token })

        if (sk.connected) {
          setJiraLoading(false)
          setJiraConnected(true)
          return { message: '' }
        }
      } else {
        setJiraLoading(false)
        setJiraConnected(false)
        return { message: 'Jira не подключен' }
      }
    } catch (e) {
      setJiraLoading(false)
      setJiraConnected(false)
      console.log(e)
      return { message: 'Jira не подключен' }
    }
  }, [])

  // get jira Epics
  const getJiraEpics = useCallback(async (auth, id, jiraProject='dev') => {
    try {
      setJiraLoading(true)
      if (auth.user.projects.length && auth.user.projects.find(prj => prj.id === id) && auth.user.projects.find(prj => prj.id === id).jiraPass) {
        const project = auth.user.projects.find(prj => prj.id === id)

        let jiraProjectName
        switch (jiraProject) {
          case 'dev':
            jiraProjectName = project.jiraProject
            break
          case 'issues':
            jiraProjectName = project.jiraIssues
            break
          case 'support':
            jiraProjectName = project.jiraSupport
            break
          default:
            jiraProjectName = project.jiraProject
        }

        const sk = await request(`/api/jira/getProjectEpics`, 'POST', {
          jiraName: project.jiraName,
          jiraUrl: project.jiraUrl,
          jiraPass: project.jiraPass,
          jiraProjectName
        }, {authorization: 'Bearer ' + auth.token})
        if (sk.connected) {
          // check epic status: all in planning = planning, have one in work = in work, all finished = finished
          setJiraConnected(true)
          sk.epics.forEach(e => setEpicStatus(sk.statuses, e))
          setJiraLoading(false)
          return { jiraStatuses: sk.statuses, jiraEpics: sk.epics, jiraTasks: sk.allTasks, message: '' }
        }
      } else {
        setJiraLoading(false)
        setJiraConnected(false)
        return { jiraStatuses: [], jiraEpics: [], jiraTasks: [], message: 'Jira не подключен' }
      }
    } catch (e) {
      setJiraLoading(false)
      setJiraConnected(false)
      console.log(e)
      return { jiraStatuses: [], jiraEpics: [], jiraTasks: [], message: 'Jira не подключен' }
    }
  }, [])

  const calcEpicProgress = (epic) => {
    try {
      const progress = Math.floor(epic.tasks.filter(ts => ts.status.name === 'Готово').length * 100 / epic.tasks.length)
      return !isNaN(progress) ? progress : 0
    } catch (e) {
      console.log(e)
    }
    return 0
  }

  return { getJiraUsers, getJiraEpics, jiraLoading, jiraConnected, calcEpicProgress, getHistory, getTransitions, makeTransitions }
}