import { SET_MODE_PROFILE, SET_MODE_PROJECT, SET_PROJECT_ID } from '../types/types'

export const setModeProject = () => {
  return {
    type: SET_MODE_PROJECT
  }
}

export const setModeProfile = () => {
  return {
    type: SET_MODE_PROFILE
  }
}

export const setProjectId = (id) => {
  return {
    type: SET_PROJECT_ID,
    payload: id
  }
}
