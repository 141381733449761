import { AUTH_LOGIN, AUTH_LOGOUT } from '../types/types'

const storageName = 'userDataITWorks'

/**
 *
 * @param data (token, user)
 * @returns {{payload, type: string}}
 */
export const login = (data) => {
 localStorage.setItem(storageName, JSON.stringify({userId: data.user.id, token: data.token, user: data.user}))
 return {
  type: AUTH_LOGIN,
  payload: data
 }
}

export const logout = () => {
 localStorage.removeItem(storageName)
 return {
  type: AUTH_LOGOUT
 }
}

export const checkLogged = () => {
 return dispatch => {
  const data = JSON.parse(localStorage.getItem(storageName))

  // todo add check exists user in dbase?

  if (data && data.token && data.user) {
   dispatch(login(data))
  } else {
   dispatch(logout())
  }
 }
}