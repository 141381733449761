import { Offcanvas } from 'react-bootstrap'
import React, { useCallback, useEffect, useState } from 'react'
import { Loader } from '../../partials/Loader'
import { Icon } from '../../partials/Icon'
import { useHttp } from '../../../hooks/http.hook'
import { useSelector } from 'react-redux'
import { useMessage } from '../../../hooks/message.hook'
import { InlineLoader } from '../../partials/InlineLoader'
import { useJira } from '../../../hooks/jira.hook'

export const IssueDetails = ({ options, onClose }) => {
  const auth = useSelector(state => state.auth)
  const id = useSelector(state => state.sidebar.projectId)
  const { loading, request, error, clearError } = useHttp()
  const { getTransitions, jiraLoading, jiraConnected, makeTransitions } = useJira()
  const message = useMessage()
  const [comments, setComments] = useState([])
  const [changed, setChanged] = useState(false)
  const [comment, setComment] = useState({
    issueId: '',
    author: '',
    text: '',
  })

  useEffect(() => {
    message(error)
    clearError()
  }, [error, message, clearError])

  const getComments = useCallback(async (token, id) => {
    try {
      if (token && id) {
        const data = await request(`/api/issue/${id}`, 'GET', null, { authorization: 'Bearer ' + token })
        setComments(data.comments)
      }
    } catch (e) {
      console.log(e)
    }
  }, [request])

  useEffect(() => {
    let headerEl = document.querySelector('.offcanvas-header')
    if (headerEl) headerEl.style.paddingBottom = 0;
  }, [])

  useEffect(() => {
    (async () => {
      setComment({ ...comment, author: auth.user.name || auth.user.email, issueId: options.task.id })
      await getComments(auth.token, options.task.id)
    })()
  }, [auth.token, options.task.id])

  const changeHandler = event => {
    setComment({ ...comment, [event.target.name]: event.target.value })
  }

  const saveComment = async event => {
    if (event.keyCode === 13) {
      try {
        const data = await request('/api/issue/create', 'POST', { comment }, { authorization: 'Bearer ' + auth.token })
        setComment({...comment, text: ''})
        message(data.message)
        await getComments(auth.token, options.task.id)
      } catch (e) {
        console.log(e)
      }
    }
  }
  const changeStatusHandler = async (e) => {
    if (options && options.task && options.task.id) {
      let k = Object.keys(options.statuses).find((key => options.statuses[key] === e.target.value))

      const transitions = await getTransitions(auth, id, options.task.id)

      let tId = transitions.issueTransitions['transitions'].find(el => el.to.name.toLowerCase() === k)

      const makeTrans = await makeTransitions(auth, id, options.task.id, tId.id)

      setChanged(true)
    }
  }

  useEffect(() => {
    (async () => {
      if (options && options.task && options.task.id) {
        const res = await getTransitions(auth, id, options.task.id)
        console.log(options.task)
        console.log(res)
      }
    })()
  }, [options.task, auth, id])
  
  if (!options.task) {
    return <Loader/>
  }

  return (
    <Offcanvas show={options.show} onHide={() => {onClose(changed)}} placement={'end'}>
      <Offcanvas.Header closeButton>
        <div className="row flex-column">
          <div className="col-auto">
            <span style={{fontSize: '15px'}}>id {options.task.id}</span> <span className="text-gray" style={{fontSize: '13px'}}>от {new Date(options.task.fields.created).toLocaleDateString('ru-RU', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
          })}</span>
          </div>
          <div className="col-auto">
            <Offcanvas.Title><span style={{fontSize: '24px'}}>{options.task.summary}</span></Offcanvas.Title>
          </div>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <span style={{fontSize: '15px'}} className="text-gray">{options.task.description ? options.task.description.content.map(c1 => c1.content.map(c2 => c2.text).join(' ')).join('\n') : 'Нет описания'}</span>
        <div className="row mt-4" style={{fontSize: '15px'}}>
          <div className="col-4">Проект:</div>
          <div className="col-8"><Icon name="Tag" size="16px"/>{options.task.parent ? options.task.parent.fields.summary : '-'}</div>
        </div>

        {/*<div className="row mt-2 mb-3" style={{fontSize: '15px'}}>*/}
        {/*  <div className="col-4">Статус:</div>*/}
        {/*  <div className="col-8">{options.statuses[options.task.status.name.toLowerCase()] || ''}</div>*/}
        {/*</div>*/}

        <div className="row mt-2 mb-3" style={{fontSize: '15px'}}>
          <div className="col-4 align-self-center">Статус:</div>
          <div className="col-8">
            <div className="input-field">
              <select
                id="type"
                name="status"
                defaultValue={options.statuses[options.task.status.name.toLowerCase()]}
                className="form-select"
                onChange={changeStatusHandler}
              >
                {options.statuses && Object.keys(options.statuses).length ? Object.keys(options.statuses).map(st => {return(
                  <option value={options.statuses[st]} key={st}>{options.statuses[st]}</option>
                )}) : <option value="">-</option>}
              </select>
            </div>
          </div>
        </div>

        <span style={{fontSize: '18px'}}>Комментарии</span>

        { !loading && comments.length ?
          <div className="mt-3">
            {
              comments.map(c => {
                return (
                  <div className="row mb-2" key={c.id}>
                    <div className="col-1 me-2">
                      <div  style={{width: '32px', height: '32px', borderRadius: '50%', background: '#C4C4C4', marginTop: '5px'}} />
                    </div>
                    <div className="col-auto d-flex flex-column">
                      <span style={{fontSize: '15px', fontWeight: '600'}}>{ c.author }</span>
                      <span style={{fontSize: '15px', fontWeight: '400'}}>{ c.text }</span>
                      <span style={{fontSize: '13px', fontWeight: '400'}}>{ new Date(c.createdAt).toLocaleDateString('ru-RU', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric'
                      }) }</span>
                    </div>
                  </div>
                )
              })
            }
          </div>
         :
          loading && <InlineLoader size={20} ml={1} />
        }

        <div className="input-field mt-3">
          <input
            type="text"
            id="text"
            className="form-control"
            name="text"
            placeholder="Добавить комментарий"
            value={comment.text}
            onChange={changeHandler}
            onKeyDown={saveComment}
          />
        </div>

      </Offcanvas.Body>
    </Offcanvas>
  )
}