import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHttp } from '../../../hooks/http.hook'
import { useMessage } from '../../../hooks/message.hook'
import { Link, useHistory } from 'react-router-dom'
import { logout } from '../../../redux/actions/auth.actions'
import { InlineLoader } from '../../partials/InlineLoader'
import { useJira } from '../../../hooks/jira.hook'
import { Loader } from '../../partials/Loader'
import { setActiveItem, setActiveSubItem, showSubMenu } from '../../../redux/actions/navbar.actions'
// import { MergeIcon } from '../../partials/MergeIcon'
import { useDate } from '../../../hooks/date.hook'
import { Icon } from '../../partials/Icon'
import { NoTable } from '../../partials/NoTable'
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar'
import { SprintDetails } from './SprintDetails'
import { useSprint } from '../../../hooks/sprint.hook'

export const SprintPage = ({status = 'Все'}) => {
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const id = useSelector(state => state.sidebar.projectId)
  const { loading, request, error, clearError, silentRequest } = useHttp()
  const message = useMessage()
  const history = useHistory()
  const { jiraLoading, jiraConnected, getJiraEpics } = useJira()
  const { getClosest, addDays, daysBetween, whereDate } = useDate()
  const {calcProgress, sprintStatus, sprintDateDiff, team} = useSprint()
  const [project, setProject] = useState(null)
  const [statuses] = useState({
    'Все': {
      title: 'Список спринтов',
      subTitle: 'Отображаются все спринты'
    },
    'Текущие': {
      title: 'Список текущих спринтов',
      subTitle: 'Отображаются спринты, которые сейчас выполняются'
    },
    'Запланировано': {
      title: 'Список запланированных спринтов',
      subTitle: 'Отображаются как спринты в статусе черновик, так и запланированные спринты'
    },
    'Завершено': {
      title: 'Список завершённых спринтов',
      subTitle: 'Отображаются спринты с истёкшим сроком выполнения'
    }
  })
  const [title, setTitle] = useState('Список спринтов')
  const [subTitle, setSubTitle] = useState('Отображаются все спринты')
  const [show, setShow] = useState(false)
  const [sprint, setSprint] = useState(null)
  const [sprints, setSprints] = useState(null)
  const [jiraTasks, setJiraTasks] = useState([])


  useEffect(() => {
    message(error)
    if (error === 'Нет авторизации') {
      clearError()
      dispatch(logout())
      history.push('/')
    }
    clearError()
  }, [error, message, clearError, logout, history])

  const getProject = useCallback(async (token, id) => {
    try {
      if (token && id) {
        const data = await request(`/api/project/sprints/${id}`, 'GET', null, { authorization: 'Bearer ' + token })
        setProject(data.project)

        // get jira and check connection
        const jira = await getJiraEpics(auth, id, 'dev')
        setJiraTasks(jira.jiraTasks)

        // Filtering
        switch (status) {
          case 'Текущие':
            setSprints([...data.project.sprints.filter(s => sprintStatus(s) === 'Идёт' || (sprintStatus(s) === 'Черновик' && whereDate(s.startDay, s.endDay, new Date()) === 'in period'))])
            break
          case 'Запланировано':
            setSprints([...data.project.sprints.filter(s => sprintStatus(s) === 'Запланировано' || (sprintStatus(s) === 'Черновик' && whereDate(s.startDay, s.endDay, new Date()) === 'before'))])
            break
          case 'Завершено':
            setSprints([...data.project.sprints.filter(s => sprintStatus(s) === 'Завершено')])
            break
          default:
            setSprints(data.project.sprints)
        }

        setTitle(statuses[status].title)
        setSubTitle(statuses[status].subTitle)

      }
    } catch (e) {
      console.log(e)
    }
  }, [request])

  const showModal = (sprint) => {
    setSprint(sprint)
    // show sidebar
    setShow(true)
  }

  const onClose = () => {
    // setShow(false)
    // setSprint(null)
  }

  const updateOnChange = async () => {
    setSprint(null)
    setShow(false)
    await getProject(auth.token, id)
  }

  const createSprint = async () => {
    try {
      const startDay = +project.sprintSetting.startDay // day of week
      const sprintDuration = +project.sprintSetting.sprintDuration
      const draftForward = +project.sprintSetting.draftForward

      let newSprints = []

      // if exists sprints and last sprint not finished then closestStartDayDate = lastDay of this sprint
      let closestStartDayDate = getClosest(startDay)

      if (sprints && sprints.length) {
        const max = sprints.reduce(function(prev, current) {
          return (prev.id > current.id) ? prev : current
        })

        if (max && max.endDay && (new Date() < new Date(max.endDay))) {
          closestStartDayDate = new Date(max.endDay)
        }
      }

      let endDate = addDays(closestStartDayDate, 7 * sprintDuration)

      for (let i=0; i<=draftForward; i++) {
        newSprints.push({
          projectId: id,
          startDay: closestStartDayDate,
          endDay: endDate,
          branch: `sprint_${+closestStartDayDate}`
        })

        closestStartDayDate = endDate
        endDate = addDays(closestStartDayDate, 7 * sprintDuration)
      }

      // create sprints (save to sprints)
      const data = await request(`/api/sprint/create`, 'POST', {sprints: newSprints}, { authorization: 'Bearer ' + auth.token })
      message(data.message)
      // on backend create git branch?
      for (let i = 0; i < data.sprints.length; i++) {
        await createBranch(data.sprints[i].branch)
      }

      // reload project
      await getProject(auth.token, id)

    } catch (e) {
      console.log(e)
    }
  }
  
  const createBranch = async (branch) => {
    try {
      if (project) {
        await silentRequest(`/api/git/createBranch`, 'POST', {
          repo: project.gitRepo,
          gitToken: project.gitToken,
          mainBranch: project.gitMaster,
          branch
        }, { authorization: 'Bearer ' + auth.token })
      }
    } catch (e) {
      console.log(e)
    }
  }

  // load project
  useEffect(() => {
    (async () => {
      await getProject(auth.token, id)
    })()

  }, [getProject, id, auth.token])

  // create sprints
  useEffect(() => {
    (async () => {
      if (jiraConnected && status === 'Все' && project && project.sprints) {
        // if daysBetween current date and endDay of last sprint < sprintDuration or No sprints yet
        if (!project.sprints.length) {
          await createSprint()
          await getProject(auth.token, id)
        } else {
          const max = project.sprints.reduce(function(prev, current) {
            return (prev.id > current.id) ? prev : current
          })

          if (max) {
            const datePos = whereDate(max.startDay, max.endDay, new Date())

            switch (datePos) {
              case 'before':
                return
              case 'after':
                await createSprint()
                await getProject(auth.token, id)
                return
              default:
                if (daysBetween(new Date(), max.endDay) < +project.sprintSetting.sprintDuration) {
                  await createSprint()
                  await getProject(auth.token, id)
                  return
                }
            }

          } else {
            await createSprint()
            await getProject(auth.token, id)
          }
        }
      }
    })()
  }, [project, jiraConnected, getProject, id, auth.token, status])

  if (!project || loading || jiraLoading) {
    return <Loader/>
  }


  return (
    <>
      {!jiraLoading && !jiraConnected ?
        <div className="row justify-content-center">
          <div className="col-12">
            <div style={{
              backgroundColor: '#fff4cd',
              color: '#866607',
              padding: '10px',
              textAlign: 'justify',
              marginBottom: '3rem'
            }}>
              <span style={{ fontWeight: 600 }}>Важно!</span> У вас не настроена интеграция с Jira.
              Вы можете настроить интеграцию <Link to={`#`} onClick={() => {
              dispatch(setActiveItem('/settings'))
              dispatch(setActiveSubItem('/settings/integration'))
              dispatch(showSubMenu())
              history.push(`/settings/integration`)
            }}>здесь.</Link>
            </div>
          </div>
        </div>
        :
        <>
          {/*Header*/}
          <div className="row justify-content-between mb-3">
            <div className="col">
              <h5 className="mb-0" style={{ fontSize: '24px' }}>{title}</h5>
              <div className="text-gray mb16px mb-0">{subTitle} {loading && <InlineLoader size={20} ml={1}/>}</div>
            </div>

            {/*<div className="col align-self-end text-end">*/}
            {/*  <button*/}
            {/*    className="btn btn-outline-my mb16px"*/}
            {/*    type="button"*/}
            {/*    onClick={createSprint}*/}
            {/*  >Создать Sprint*/}
            {/*  </button>*/}
            {/*</div>*/}
          </div>

          <div className="row mb-3 h-100">

            {sprints && sprints.length ? (
              <div className="col-12">
                <table className="table">
                  <thead>
                  <tr>
                    <th className="text-gray" />
                    <th className="text-gray" />
                    <th className="text-gray" />
                    <th className="text-gray" />
                    <th className="text-gray" />
                    <th className="text-gray" />
                    <th className="text-gray" />
                  </tr>
                  </thead>
                  <tbody className="table-hover">
                  {sprints && sprints.length ? sprints.map(s => {
                    let progress = calcProgress(s, jiraTasks)
                    let teamProgress = team(s.SprintTasks, jiraTasks)
                    return (
                      <tr
                        style={{cursor: 'pointer'}}
                        key={s.id}
                        onClick={() => {showModal(s)}}
                      >
                        <td className="text-gray" style={{fontSize: '13px', position: 'relative'}} valign={'middle'}>
                          {new Date(s.startDay).toLocaleDateString('ru-RU', {
                            month: 'short',
                            day: '2-digit'
                          })} - {new Date(s.endDay).toLocaleDateString('ru-RU', {
                          month: 'short',
                          day: '2-digit'
                        })}
                        </td>

                        <td style={{fontSize: '15px', fontWeight: 600}} valign={'middle'}>
                            Sprint {s.id}
                        </td>

                        <td>
                            <div className="row">
                              <div className="col-auto align-self-center">
                                <div
                                  className="d-flex justify-content-center"
                                  style={{
                                    width: '40px',
                                    height: '40px',
                                    border: !progress ? '1px solid rgba(0,0,0, .15)' : '1px solid #6A4EF4',
                                    borderRadius: '50%'
                                  }}
                                >
                                  <div className="align-self-center" style={{ width: '85%', height: '85%' }}>
                                    <CircularProgressbarWithChildren
                                      strokeWidth={50}
                                      styles={buildStyles({
                                        strokeLinecap: 'butt',
                                        pathColor: '#6A4EF4',
                                        trailColor: 'transparent'
                                      })}
                                      value={progress}
                                    >
                                      {/*<CircularProgressbar*/}
                                      {/*  strokeWidth={2}*/}
                                      {/*  styles={buildStyles({*/}
                                      {/*    pathColor: 'rgba(0,0,0, 0.15)',*/}
                                      {/*    // backgroundColor: '#fff'*/}
                                      {/*  })}*/}
                                      {/*  value={100}*/}
                                      {/*/>*/}
                                    </ CircularProgressbarWithChildren>
                                  </div>
                                </div>
                              </div>
                              <div className="col-auto">
                                <p className="mb-0" style={{fontSize: '15px', fontWeight: 600}}>{sprintStatus(s)}</p>
                                <p className="text-gray mb-0" style={{fontSize: '13px'}}>статус</p>
                              </div>
                            </div>
                        </td>

                        <td>
                          {sprintDateDiff(s)}
                        </td>

                        <td>
                          <p className="mb-0" style={{fontSize: '15px', fontWeight: 600}}>{teamProgress.ready}/{s.SprintTasks.length}</p>
                          {/*<p className="mb-0" style={{fontSize: '15px', fontWeight: 600}}>{s.SprintTasks.filter(ts => ts.status.name === 'Готово').length}/{s.SprintTasks.length}</p>*/}
                          <p className="text-gray mb-0" style={{fontSize: '13px'}}>задач</p>
                        </td>

                        <td>
                          <p className="mb-0" style={{fontSize: '15px', fontWeight: 600}}>{Object.keys(teamProgress).filter(el => el !== 'ready').length ? `${Object.keys(teamProgress).filter(el => el !== 'ready').length} чел.` : 'Отсутств.'}</p>
                          <p className="text-gray mb-0" style={{fontSize: '13px'}}>команда</p>
                        </td>

                        <td>
                          <div className="row justify-content-center align-content-center">
                            <div className="col-auto align-self-center">
                              <Icon name="Git" size="18px" mr={0}/>
                            </div>
                            <div className="col auto">
                              <p className="mb-0" style={{ fontSize: '12px' }}>{project.gitRepo}/{s.branch}</p>
                              <p className="mb-0 text-gray" style={{ fontSize: '10px' }}>Git branch</p>
                            </div>
                          </div>
                        </td>

                      </tr>
                    )
                  }) : (<></>)}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="col pt-5">
                <NoTable params={{ title: `В этом проекте пока нет спринтов` }}/>
              </div>
            )}

          </div>
        </>
      }

      {sprint ? <SprintDetails options={{ sprint, project, show }} updateOnChange={updateOnChange} onClose={onClose} /> : <></>}

    </>
  )
}

// <p><MergeIcon /></p>
// <p><MergeIcon color={'#1890FF'}/></p>
// <p><MergeIcon color={'#08979C'}/></p>
// <p><i style={{color: 'red', fontSize: '25px'}} className="fa fa-times-circle-o" aria-hidden="true"/></p>
// <p><i style={{color: '#00D17C', fontSize: '25px'}} className="fa fa-check-circle-o" aria-hidden="true"/></p>
