import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/actions/auth.actions'
import { NoTable } from '../partials/NoTable'
import { useHttp } from '../../hooks/http.hook'
import { useMessage } from '../../hooks/message.hook'
import { Link, useHistory } from 'react-router-dom'
import { Loader } from '../partials/Loader'
import { useJira } from '../../hooks/jira.hook'
import { setActiveItem, setActiveSubItem, showSubMenu } from '../../redux/actions/navbar.actions'
import { InlineLoader } from '../partials/InlineLoader'
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar'
import { Icon } from '../partials/Icon'
import { useSprint } from '../../hooks/sprint.hook'

export const MainPage = () => {
  const id = useSelector(state => state.sidebar.projectId)
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const { loading, request, error, clearError, silentRequest } = useHttp()
  const message = useMessage()
  const history = useHistory()
  const [project, setProject] = useState(null)
  const { jiraLoading, jiraConnected, getJiraEpics, calcEpicProgress, getHistory } = useJira()
  const {calcProgress, team, sprintStatus, sprintDateDiff, getRecommendations} = useSprint()
  const [jiraTasks, setJiraTasks] = useState([])
  const [jiraEpics, setJiraEpics] = useState([])
  const [currentSprint, setCurrentSprint] = useState(null)
  const [futureSprints, setFutureSprints] = useState(null)
  const [lastThreeFinishedSprintsStat, setLastThreeFinishedSprintsStat] = useState(null)
  const [recomm, setRecomm] = useState([])
  const {computeSprints} = useSprint()


  // noinspection DuplicatedCode
  useEffect(() => {
    message(error)
    if (error === 'Нет авторизации') {
      clearError()
      dispatch(logout())
      history.push('/')
    }
    clearError()
  }, [error, message, clearError, logout, history])

  const moveTo = (item, subItem = '') => {
    dispatch(setActiveItem(item))
    if (subItem) {
      dispatch(setActiveSubItem(subItem))
      dispatch(showSubMenu())
      history.push(subItem)
    } else {
      history.push(item)
    }
  }

  const getProject = useCallback(async (token, id) => {
    try {
      if (token && id) {
        const data = await request(`/api/project/sprints/${id}`, 'GET', null, { authorization: 'Bearer ' + token })
        setProject(data.project)

        // get jira and check connection
        const jira = await getJiraEpics(auth, id, 'dev')
        setJiraTasks(jira.jiraTasks)
        setJiraEpics(jira.jiraEpics)

        const sprintsStat = await computeSprints(data.project.sprints, jira.jiraTasks, id, auth)
        const currSprint = sprintsStat.currentSprint ? [sprintsStat.currentSprint] : []
        setCurrentSprint(currSprint)

        setFutureSprints(sprintsStat.plannedAndDraftSprints)
        setLastThreeFinishedSprintsStat(sprintsStat.lastThreeFinishedSprintsStat)

        if (currSprint.length) {
          let teamProg = team(currSprint[0].SprintTasks, jira.jiraTasks)
          setRecomm([...getRecommendations(currSprint[0], jira.jiraTasks, teamProg)])
        }

        // history of all changes in issue
        // let hist = await getHistory(auth, id, sprintsStat.filteredJiraTasks[3].key)
        // console.log('hist', hist.issueHistory)

        // console.log(jira.jiraStatuses)
        // console.log('sprintsStat', sprintsStat)
      }
    } catch (e) {
      console.log(e)
    }
  }, [request])

  useEffect(() => {
    (async () => {
      await getProject(auth.token, id)
    })()

  }, [getProject, id, auth.token])

  const setProgress = (stats, period, type) => {
    if (stats) {
      let title, backgroundColor

      switch (period) {
        case 0:
          title = '< 1 часа'
          backgroundColor = '#08979C'
          break
        case 1:
          title = '1 - 4 часа'
          backgroundColor = '#08979C'
          break
        case 2:
          title = '4 - 8 часов'
          backgroundColor = '#08979C'
          break
        case 3:
          title = '> 8 часов'
          backgroundColor = '#D48806'
          break
        default:
          title = '< 1 часа'
          backgroundColor = '#08979C'
      }

      let progress = Math.floor(stats[type][period]*100 / stats.tasksTotal )
      progress = isNaN(progress) ? 0 : progress

      return (
        <>
          <div className="row justify-content-between mb-0">
            <div className="col-auto">
              <span className="" style={{ fontSize: '13px' }}>{title}</span>
            </div>
            <div className="col-auto">
              <span className="text-gray" style={{ fontSize: '13px' }}>{`${stats[type][period]} задач`}</span>
              <span className="" style={{ fontSize: '13px' }}>{` ${progress}%`}</span>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-12">
              <div className="progress" style={{ height: '8px' }}>
                <div className="progress-bar" role="progressbar"
                     style={{ width: `${progress}%`, backgroundColor }} aria-valuemin="0"
                     aria-valuemax="100"/>
              </div>
            </div>
          </div>
        </>
      )
    } else {
      return <></>
    }
  }

  const showSprints = (sprints) => {
    return (
      <>
        {sprints && sprints.length ? (
          <div className="col-12">
            <table className="table">
              <thead>
              <tr>
                <th className="text-gray" />
                <th className="text-gray" />
                <th className="text-gray" />
                <th className="text-gray" />
                <th className="text-gray" />
                <th className="text-gray" />
                <th className="text-gray" />
              </tr>
              </thead>
              <tbody className="table-hover">
              {sprints && sprints.length ? sprints.map(s => {
                let progress = calcProgress(s, jiraTasks)
                let teamProgress = team(s.SprintTasks, jiraTasks)
                return (
                  <tr
                    key={s.id}
                  >
                    <td className="text-gray" style={{fontSize: '13px', position: 'relative', width: '180px'}} valign={'middle'}>
                      {new Date(s.startDay).toLocaleDateString('ru-RU', {
                        month: 'short',
                        day: '2-digit'
                      })} - {new Date(s.endDay).toLocaleDateString('ru-RU', {
                      month: 'short',
                      day: '2-digit'
                    })}
                    </td>

                    <td style={{fontSize: '15px', fontWeight: 600, width: '100px'}} valign={'middle'}>
                      Sprint {s.id}
                    </td>

                    <td style={{width: '280px'}}>
                      <div className="row">
                        <div className="col-auto align-self-center">
                          <div
                            className="d-flex justify-content-center"
                            style={{
                              width: '40px',
                              height: '40px',
                              border: !progress ? '1px solid rgba(0,0,0, .15)' : '1px solid #6A4EF4',
                              borderRadius: '50%'
                            }}
                          >
                            <div className="align-self-center" style={{ width: '85%', height: '85%' }}>
                              <CircularProgressbarWithChildren
                                strokeWidth={50}
                                styles={buildStyles({
                                  strokeLinecap: 'butt',
                                  pathColor: '#6A4EF4',
                                  trailColor: 'transparent'
                                })}
                                value={progress}
                              >
                              </ CircularProgressbarWithChildren>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto">
                          <p className="mb-0" style={{fontSize: '15px', fontWeight: 600}}>{sprintStatus(s)}</p>
                          <p className="text-gray mb-0" style={{fontSize: '13px'}}>статус</p>
                        </div>
                      </div>
                    </td>

                    <td style={{width: '115px'}}>
                      {sprintDateDiff(s)}
                    </td>

                    <td>
                      <p className="mb-0" style={{fontSize: '15px', fontWeight: 600}}>{teamProgress.ready}/{s.SprintTasks.length}</p>
                      {/*<p className="mb-0" style={{fontSize: '15px', fontWeight: 600}}>{s.SprintTasks.filter(ts => ts.status.name === 'Готово').length}/{s.SprintTasks.length}</p>*/}
                      <p className="text-gray mb-0" style={{fontSize: '13px'}}>задач</p>
                    </td>

                    <td style={{width: '120px'}}>
                      <p className="mb-0" style={{fontSize: '15px', fontWeight: 600}}>{Object.keys(teamProgress).filter(el => el !== 'ready').length ? `${Object.keys(teamProgress).filter(el => el !== 'ready').length} чел.` : 'Отсутств.'}</p>
                      <p className="text-gray mb-0" style={{fontSize: '13px'}}>команда</p>
                    </td>

                    <td>
                      <div className="row justify-content-center align-content-center">
                        <div className="col-auto align-self-center">
                          <Icon name="Git" size="18px" mr={0}/>
                        </div>
                        <div className="col auto">
                          <p className="mb-0" style={{ fontSize: '12px' }}>{project.gitRepo}/{s.branch}</p>
                          <p className="mb-0 text-gray" style={{ fontSize: '10px' }}>Git branch</p>
                        </div>
                      </div>
                    </td>

                  </tr>
                )
              }) : (<></>)}
              </tbody>
            </table>
          </div>
        ) : (
          <div>
            <p className="text-gray">пока нет спринтов</p>
          </div>
        )}
      </>
    )
  }


  return (
    <>
      {project && project.name ?
        <>
          {!jiraLoading && !jiraConnected ?
            <div className="row justify-content-center">
              <div className="col-12">
                <div style={{
                  backgroundColor: '#fff4cd',
                  color: '#866607',
                  padding: '10px',
                  textAlign: 'justify',
                  marginBottom: '3rem'
                }}>
                  <span style={{ fontWeight: 600 }}>Важно!</span> У вас не настроена интеграция с Jira.
                  Вы можете настроить интеграцию <Link to={`#`} onClick={() => {
                  moveTo('/settings', `/settings/integration`)
                }}>здесь.</Link>
                </div>
              </div>
            </div>
            :
            <>
              {/*Header*/}
              <div className="row justify-content-between mb-3">
                <div className="col">
                  <h5 className="mb-0" style={{ fontSize: '30px', fontWeight: 700 }}>Общая информация по
                    проекту {jiraLoading ? <InlineLoader size={20} ml={1}/> : ''}</h5>
                  <div className="text-gray mb-0" style={{ fontSize: '20px' }}>Следите за самыми важными показателями
                    работы команды
                  </div>
                </div>
              </div>

              {/*Показатели по работе команды*/}
              <div className="row mb-4">
                <div className="col-12">
                  {/*Title*/}
                  <div className="row justify-content-between mb-2">
                    <div className="col-auto">
                      <h5 className="mb-0" style={{ fontSize: '24px', fontWeight: 400 }}>Показатели команды по работе с задачами за последние 3 спринта {jiraLoading || !lastThreeFinishedSprintsStat ? <InlineLoader size={20} ml={1}/> : ''}</h5>
                    </div>
                    <div className="col-auto align-self-center">
                      <p className="mb-0 text-gray text-decoration-underline"
                         style={{ fontSize: '15px', cursor: 'pointer' }}
                         onClick={() => {history.push('/sprintCompare')}}>Подробнее</p>
                    </div>
                  </div>

                  {/*Body*/}
                  <div className="row">
                    <div className="col-6">
                      <p className="" style={{ fontSize: '18px' }}>Время "Реакции"</p>

                      {/*< 1 hour*/}
                      {lastThreeFinishedSprintsStat && setProgress(lastThreeFinishedSprintsStat, 0, 'todo2work')}
                      {/*1 - 4 hour*/}
                      {lastThreeFinishedSprintsStat && setProgress(lastThreeFinishedSprintsStat, 1, 'todo2work')}
                      {/*4 - 8 hour*/}
                      {lastThreeFinishedSprintsStat && setProgress(lastThreeFinishedSprintsStat, 2, 'todo2work')}
                      {/*> 8 hour*/}
                      {lastThreeFinishedSprintsStat && setProgress(lastThreeFinishedSprintsStat, 3, 'todo2work')}

                      <p className="text-gray" style={{ fontSize: '13px' }}>Время до момента перевода задачи в статус “В
                        работе”</p>
                    </div>

                    {/*<div className="col-2"/>*/}

                    <div className="col-6">
                      <p className="" style={{ fontSize: '18px' }}>Время "Исполнения"</p>

                      {/*< 1 hour*/}
                      {lastThreeFinishedSprintsStat && setProgress(lastThreeFinishedSprintsStat, 0, 'work2done')}
                      {/*1 - 4 hour*/}
                      {lastThreeFinishedSprintsStat && setProgress(lastThreeFinishedSprintsStat, 1, 'work2done')}
                      {/*4 - 8 hour*/}
                      {lastThreeFinishedSprintsStat && setProgress(lastThreeFinishedSprintsStat, 2, 'work2done')}
                      {/*> 8 hour*/}
                      {lastThreeFinishedSprintsStat && setProgress(lastThreeFinishedSprintsStat, 3, 'work2done')}

                      <p className="text-gray" style={{ fontSize: '13px' }}>Время перевода задачи из статуса “В работе”
                        в статус “Сделано”</p>
                    </div>
                  </div>

                </div>

              </div>

              {/*Roadmap*/}
              <div className="row mb-4">
                <div className="col-12">
                  {/*Title*/}
                  <div className="row justify-content-between mb-2">
                    <div className="col-auto">
                      <p className="mb-0" style={{ fontSize: '24px', fontWeight: 400 }}>Статус по Roadmap</p>
                    </div>
                    <div className="col-auto align-self-center">
                      <p className="mb-0 text-gray text-decoration-underline"
                         style={{ fontSize: '15px', cursor: 'pointer' }}
                         onClick={() => {moveTo('/roadmap')}}>Подробнее</p>
                    </div>
                  </div>

                  {/*Body*/}
                  <div className="row">
                    {jiraEpics.map(epic => {
                      return (
                        <div className="col-4" key={epic.id}>
                          <div className="card">
                            <div className="row">
                              <div className="col-auto">
                                <div
                                  className="d-flex justify-content-center"
                                  style={{
                                    width: '40px',
                                    height: '40px',
                                    border: !calcEpicProgress(epic) ? '1px solid rgba(0,0,0, .15)' : '1px solid #6A4EF4',
                                    borderRadius: '50%'
                                  }}
                                >
                                  <div className="align-self-center" style={{ width: '85%', height: '85%' }}>
                                    <CircularProgressbarWithChildren
                                      strokeWidth={50}
                                      styles={buildStyles({
                                        strokeLinecap: 'butt',
                                        pathColor: '#6A4EF4',
                                        trailColor: 'transparent'
                                      })}
                                      value={calcEpicProgress(epic)}
                                    >
                                    </ CircularProgressbarWithChildren>
                                  </div>
                                </div>
                              </div>
                              <div className="col-auto">
                                <p className="mb-0">{epic.summary}</p>
                                <p className="mb-0 text-gray"
                                   style={{ fontSize: '10px' }}>{epic.tasks.length ? `${calcEpicProgress(epic)}% завершено` : `Небходима декомпозиция`}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>

                </div>

              </div>

              {/*Current Sprint*/}
              <div className="row mb-4">
                <div className="col-12">
                  {/*Title*/}
                  <div className="row justify-content-between mb-2">
                    <div className="col-auto">
                      <p className="mb-0" style={{ fontSize: '24px', fontWeight: 400 }}>Текущий спринт</p>
                    </div>
                    <div className="col-auto align-self-center">
                      <p className="mb-0 text-gray text-decoration-underline"
                         style={{ fontSize: '15px', cursor: 'pointer' }}
                         onClick={() => {moveTo('/sprint', '/sprint/curr')}}>Подробнее</p>
                    </div>
                  </div>

                  {/*Body*/}
                  <div className="row">
                    {currentSprint && showSprints(currentSprint)}
                  </div>
                  {recomm.length ?
                    <div className="row">
                      {recomm.map(r => {
                        return (
                          <p className="text-gray mb-1" style={{ fontSize: '14px' }} key={r}>{r}</p>
                        )
                      })}
                    </div>
                      :
                    <></>
                  }

                </div>

              </div>

              {/*Planned Sprint*/}
              <div className="row mb-4">
                <div className="col-12">
                  {/*Title*/}
                  <div className="row justify-content-between mb-2">
                    <div className="col-auto">
                      <p className="mb-0" style={{ fontSize: '24px', fontWeight: 400 }}>Будущие спринты (черновик +
                        запланировано)</p>
                    </div>
                    <div className="col-auto align-self-center">
                      <p className="mb-0 text-gray text-decoration-underline"
                         style={{ fontSize: '15px', cursor: 'pointer' }}
                         onClick={() => {moveTo('/sprint', '/sprint/planned')}}>Подробнее</p>
                    </div>
                  </div>

                  {/*Body*/}
                  <div className="row">
                    {futureSprints && showSprints(futureSprints)}
                  </div>

                </div>

              </div>

            </>}


        </>
        :
        <>
          <div className="row justify-content-center align-content-center w-100 h-100">
            <div className="col">
              {/*<NoTable params={{ title: `Выберите проект или создайте новый`, link: '/project/create', linkTitle: 'Создать проект'  }} />*/}
              <NoTable params={{ title: `Выберите проект или создайте новый` }}/>
            </div>
          </div>
        </>
      }
    </>
  )
}