import React from 'react'
import { logout } from '../../redux/actions/auth.actions'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { hideSubMenu, setActiveItem, setActiveSubItem, showSubMenu } from '../../redux/actions/navbar.actions'

export const Navbar = () => {
  const dispatch = useDispatch()
  const items = useSelector(state => state.navbar.items)
  const history = useHistory()
  const showSub = useSelector(state => state.navbar.showSub)

  const menuClickHandler = (path) => {
    history.push(path)
    dispatch(setActiveItem(path))
    // if have sub -> show else hide
    if (!showSub && items.find(el => el.path === path).sub.length) dispatch(showSubMenu())
    if (showSub && !items.find(el => el.path === path).sub.length) dispatch(hideSubMenu())
  }

  const subMenuClickHandler = (path) => {
    history.push(path)
    dispatch(setActiveSubItem(path))
  }

  return (
    <>
    <ul className="nav" style={{background: !showSub ? '#fbfbfb' : '#fff', zIndex: 500}}>
      {items.length ? items.map(item => {return (
        <li className="nav-item" key={item.name}>
          <span className={`nav-link ${item.active && 'active'}`} onClick={() => {menuClickHandler(item.path)}}><p>{item.name}{item.badge ? ` ${item.badge}` : ''}</p></span>
        </li>
      )}) : <></>}
      <li className="nav-item">
        <span className="nav-link" onClick={() => {dispatch(logout())}}>Logout</span>
      </li>
    </ul>

    <ul id="subNav" className="nav" style={{display: showSub ? 'flex' : 'none', zIndex: 500}}>
      {items.length && items.find(el => el.active === true).sub.length ? items.find(el => el.active === true).sub.map(item => {return (
        <li className="nav-item" key={item.name}>
          <span className={`nav-link ${item.active && 'active'}`} onClick={() => {subMenuClickHandler(item.path)}}><p>{item.name}{item.badge ? ` ${item.badge}` : ''}</p></span>
        </li>
      )}) : <></>}
    </ul>
    </>
  )
}