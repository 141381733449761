import { setBudge } from '../redux/actions/navbar.actions'
import { useDispatch, useSelector } from 'react-redux'
import { useSprint } from './sprint.hook'
import { useHttp } from './http.hook'

export const useSprintStats = () => {
  const auth = useSelector(state => state.auth)
  const {silentRequest} = useHttp()
  const {sprintStatus} = useSprint()
  const dispatch = useDispatch()

  const getSprintStats = async (prjId) => {
    const data = await silentRequest(`/api/project/sprints/${prjId}`, 'GET', null, { authorization: 'Bearer ' + auth.token })

    let sprints = data.project.sprints

    let curr = []
    let planned = []
    let finished = []
    let total = [...sprints]

    if (sprints && sprints.length) {
      sprints.forEach(s => {
        const stat = sprintStatus(s)
        switch (stat) {
          case 'Идёт':
            curr.push(s)
            break
          case 'Запланировано':
            planned.push(s)
            break
          case 'Черновик':
            planned.push(s)
            break
          case 'Завершено':
            finished.push(s)
            break
          default:
            planned.push(s)
        }
      })

      // here push badgets to navbar
      dispatch(setBudge('/sprint', sprints.length.toString()))
      dispatch(setBudge('/sprint', sprints.length.toString(), true))
      dispatch(setBudge('/sprint/curr', curr.length.toString(), true))
      dispatch(setBudge('/sprint/planned', planned.length.toString(), true))
      dispatch(setBudge('/sprint/finished', finished.length.toString(), true))
    } else {
      // here push badgets to navbar
      dispatch(setBudge('/sprint', ''))
      dispatch(setBudge('/sprint', '', true))
      dispatch(setBudge('/sprint/curr', '', true))
      dispatch(setBudge('/sprint/planned', '', true))
      dispatch(setBudge('/sprint/finished', '', true))
    }

    return { curr, planned, finished, total }
  }

  return { getSprintStats }
}