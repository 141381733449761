import React, {useCallback, useEffect, useState} from 'react'
import {useHistory} from "react-router-dom"
import {Loader} from "../../partials/Loader"
import {useHttp} from "../../../hooks/http.hook"
import {useMessage} from "../../../hooks/message.hook"
import { useDispatch, useSelector } from 'react-redux'
import { login, logout } from '../../../redux/actions/auth.actions'
import { InlineLoader } from '../../partials/InlineLoader'
import { hideSubMenu, setNavbarItems } from '../../../redux/actions/navbar.actions'
import { setProjectId } from '../../../redux/actions/sidebar.actions'

export const ProjectSettingsPage = () => {
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const id = useSelector(state => state.sidebar.projectId)
  const {loading, request, error, clearError} = useHttp()
  const [project, setProject] = useState(null)
  const message = useMessage()
  const history = useHistory()
  const [form, setForm] = useState({
    name: '',
    slug: ''
  })

  useEffect(() => {
    message(error)
    if (error === 'Нет авторизации') {
      clearError()
      dispatch(logout())
      history.push('/')
    }
    clearError()
  }, [error, message, clearError, logout, history])

  const getProject = useCallback(async (token, id) => {
    try {
      if (token && id > 0) {
        const data = await request(`/api/project/${id}`, 'GET', null, {authorization: 'Bearer ' + token})
        setProject(data.project)
        setForm({name: data.project.name, slug: data.project.slug})
      }
    } catch (e) {
      console.log(e)
    }
  }, [request])

  useEffect(() => {
    (async () => {
      await getProject(auth.token, id)
    })()
  }, [getProject, id, auth])

  const changeHandler = event => {
    setForm({ ...form, [event.target.name]: event.target.value })
  }

  const updateHandler = async () => {
    try {
      const data = await request(`/api/project/${id}`, 'PUT', {...form}, {authorization: 'Bearer ' + auth.token})
      setForm({name: data.project.name, slug: data.project.slug})
      message(data.message)
    } catch (e) {}
  }

  const deleteHandler = async () => {
    try {
      const data = await request(`/api/project/${id}`, 'DELETE', null, {authorization: 'Bearer ' + auth.token})
      message(data.message)
      dispatch(setProjectId(0))

      // update user in context
      // get user
      const usr = await request('/api/auth/getWithProjetcs', 'POST', {email: auth.user.email}, {authorization: 'Bearer ' + auth.token})
      dispatch(login({token: auth.token, user: usr.user}))

      history.push('/')
      dispatch(hideSubMenu())
      dispatch(setNavbarItems([]))
    } catch (e) {}
  }

  if (!project) {
    return <Loader />
  }

  return (
    <>
      <div className="row mb-4">
        <div className="col">
          <h5>Данные по проекту</h5>
          <div className="text-gray mb16px">Вы можете изменить данные по проекту {loading && <InlineLoader size={20} ml={1}/>}</div>
        </div>
      </div>

      <div className="row flex-column">
        <div className="col-4">
          <div className="input-field mb-3">
            <input
              placeholder="Название проекта"
              type="text"
              id="name"
              className="form-control"
              name="name"
              value={form.name}
              autoComplete="off"
              onChange={changeHandler}
            />
          </div>

          <div className="input-field mb-3">
            <input
              placeholder="Идентификатор"
              type="text"
              id="slug"
              className="form-control"
              name="slug"
              value={form.slug}
              autoComplete="off"
              onChange={changeHandler}
              disabled
              style={{borderBottom: '0px'}}
            />
          </div>

          <button
            className="btn btn-bg-blue w-100"
            onClick={updateHandler}
            disabled={loading}
          >
            Сохранить изменения
          </button>

        </div>

      </div>

      {/*<br/>*/}
      <div className="row my-3">
        <div className="col-5">
          <hr style={{display: 'block', border: '0', borderTop: '1px solid #ced4da'}}/>
        </div>
      </div>
      {/*<br/>*/}

      <div className="row">
        <div className="col-5">
          <p style={{fontSize: '1.2rem'}}>Удалить проект</p>
          <p className="text-gray">Удаление проекта также приведёт к безвозвратному удалению в ех связанных с ним сущностей. Это нельзя отменить и ваши данные не могут быть восстановлены!</p>
        </div>
      </div>

      <div className="row">
        <div className="col-4">
          <button
            className="btn btn-bg-red w-100"
            onClick={deleteHandler}
            disabled={loading}
          >
            Удалить
          </button>
        </div>
      </div>
    </>
  )

}
