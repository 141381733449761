import React from 'react'
import {Loader} from "./Loader"
import {Link} from "react-router-dom"

export const NoTable = ({params}) => {

  if (!params) {
    return <Loader />
  }

  return (
    <div className="pb-5">
      <i className="fa fa-folder-open-o text-gray text-center w-100" aria-hidden="true"
          style={{ fontSize: '7rem', opacity: 0.5 }}/>
      <p style={{ fontSize: '1.2rem' }} className="text-gray text-center">{params ? params.title : ''}</p>
      {params && params.link ? (
        <p className="text-center"><Link to={params && params.link ? params.link : ''}>{params.linkTitle}</Link></p>
      ) : (<></>)}
    </div>
  )
}
