import React, { useEffect } from 'react'
import {BrowserRouter as Router} from "react-router-dom"
import { ToastContainer } from 'react-toastify'
import {useRoutes} from "./routes"
import { useDispatch, useSelector } from 'react-redux'
import { checkLogged } from './redux/actions/auth.actions'
import { Navbar } from './components/partials/Navbar'
import { Sidebar } from './components/partials/Sidebar'

export function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    // check local storage and load to store
    dispatch(checkLogged())
  }, [dispatch])

  // get from store
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  const routes = useRoutes(isAuthenticated)

  return (
    <Router>
      <>

        <ToastContainer hideProgressBar />

        {isAuthenticated ?
          <div className="wrapper">
            {/*Sidebar*/}
            <Sidebar />

            {/*Page*/}
            <div id="content">
              <Navbar />
              <div id="page-body">
                <div className="container-fluid">
                  {routes}
                </div>
              </div>
            </div>
          </div>
          :
          <div className="container-fluid" style={{backgroundColor: '#f9f9ff'}}>
            {routes}
          </div>
        }
      </>
    </Router>
  )
}
