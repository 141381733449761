import {
  SET_NAVBAR_BUDGE,
  SET_NAVBAR_HIDE_SUB,
  SET_NAVBAR_ITEMS,
  SET_NAVBAR_ITEMS_ACTIVE,
  SET_NAVBAR_SHOW_SUB,
  SET_NAVBAR_SUBITEMS_ACTIVE
} from '../types/types'

export const setNavbarItems = (items) => {
  return {
    type: SET_NAVBAR_ITEMS,
    payload: items
  }
}

export const setActiveItem = (path) => {
  return {
    type: SET_NAVBAR_ITEMS_ACTIVE,
    payload: path
  }
}

export const setActiveSubItem = (path) => {
  return {
    type: SET_NAVBAR_SUBITEMS_ACTIVE,
    payload: path
  }
}

export const setBudge = (path, badge = '', isSub = false) => {
  return {
    type: SET_NAVBAR_BUDGE,
    payload: { path, badge, isSub }
  }
}

export const showSubMenu = () => {
  return {
    type: SET_NAVBAR_SHOW_SUB
  }
}

export const hideSubMenu = () => {
  return {
    type: SET_NAVBAR_HIDE_SUB
  }
}