import React, { useCallback, useEffect, useState } from 'react'
import { useMessage } from '../../../hooks/message.hook'
import { useHttp } from '../../../hooks/http.hook'
import { Loader } from '../../partials/Loader'
import { NoTable } from '../../partials/NoTable'
import { useDispatch, useSelector } from 'react-redux'
import { TeamForm } from './TeamForm'
import { useJira } from '../../../hooks/jira.hook'
import { Link, useHistory } from 'react-router-dom'
import { setActiveItem, setActiveSubItem, showSubMenu } from '../../../redux/actions/navbar.actions'


export const TeamPage = () => {
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const id = useSelector(state => state.sidebar.projectId)
  const { loading, request, error, clearError } = useHttp()
  const { getJiraUsers, jiraLoading, jiraConnected } = useJira()
  const message = useMessage()
  const history = useHistory()
  const [team, setTeam] = useState(null)
  const [userId, setUserId] = useState(-1)
  const [show, setShow] = useState(false)
  const [jiraUsers, setJiraUsers] = useState([]) // Jira Users


  useEffect(() => {
    message(error)
    clearError()
  }, [error, message, clearError])

  const getTeam = useCallback(async (token, id) => {
    try {
      if (token && id) {
        const data = await request(`/api/team/${id}`, 'GET', null, { authorization: 'Bearer ' + token })
        setTeam(data.team)

        // Get jira users
        const jira = await getJiraUsers(auth, id)
        setJiraUsers(jira.jiraUsers)
        if (jira.message) message(jira.message)

        // if (auth.user.projects.length && auth.user.projects.find(prj => prj.id === id) && auth.user.projects.find(prj => prj.id === id).jiraPass) {
        //   const project = auth.user.projects.find(prj => prj.id === id)
        //   const sk = await request(`/api/jira/allUsers`, 'POST', {
        //     jiraName: project.jiraName,
        //     jiraUrl: project.jiraUrl,
        //     jiraPass: project.jiraPass,
        //   }, { authorization: 'Bearer ' + token })
        //   if (sk.connected) {
        //     setConnected(true)
        //     setJiraUsers(sk.users)
        //     // console.log('Statuses', sk.statuses) // All statuses
        //
        //   }
        // } else {
        //   setConnected(false)
        //   setJiraUsers([])
        //   message('Jira не подключен')
        // }

      }
    } catch (e) {
      console.log(e)
    }
  }, [request])

  useEffect(() => {
    (async () => {
      await getTeam(auth.token, id)
    })()

  }, [getTeam, id, auth.token])

  const updateOnChange = async () => {
    await getTeam(auth.token, id)
    // hide sidebar
    setShow(false)
  }

  const showModal = (id = -new Date()) => {
    setUserId(id)
    // show sidebar
    setShow(true)
  }

  const createUser = () => {
    showModal()
  }

  const onClose = () => {
    setShow(false)
  }

  const deleteHandler = async id => {
    try {
      const data = await request(`/api/team/${id}`, 'DELETE', null, { authorization: 'Bearer ' + auth.token })
      message(data.message)
      await updateOnChange()
    } catch (e) {
      console.log(e)
    }
  }

  if (!team || loading) {
    return <Loader/>
  }

  return (
    <>
      {!jiraLoading && !jiraConnected ?
        <div className="row justify-content-center">
          <div className="col-12">
            <div style={{backgroundColor: '#fff4cd', color: '#866607', padding: '10px', textAlign: 'justify', marginBottom: '3rem'}}>
              <span style={{fontWeight: 600}}>Важно!</span> У вас не настроена интеграция с Jira.
              Вы можете настроить интеграцию <Link to={`#`} onClick={() => {
              dispatch(setActiveItem('/settings'))
              dispatch(setActiveSubItem('/settings/integration'))
              dispatch(showSubMenu())
              history.push(`/settings/integration`)
            }} >здесь.</Link>
            </div>
          </div>
        </div>
        : <></>}

      <div className="row justify-content-between mb-3">
        <div className="col">
          <h5 className="mb-0">Команда</h5>
          <p className="text-gray">Добавляйте новых участников и добавляйте скиллы текущим членам команды</p>
        </div>

        <div className="col align-self-center text-end">
          <button
            className="btn btn-outline-my mb16px"
            type="button"
            onClick={createUser}
            // disabled={!connected}
          >Добавить человека
          </button>
        </div>
      </div>

      <div className="row mb-3 h-100">

        {team && team.length ? (
          <div className="col-12">
            <table className="table">
              <thead>
              <tr>
                <th className="text-gray">Имя и Фамилия</th>
                <th className="text-gray">Связка в Jira</th>
                <th className="text-gray">Роль</th>
                <th className="text-gray">Действия</th>
              </tr>
              </thead>
              <tbody className="table-hover">
              {team && team.length ? team.map(teamUser => {
                return (
                  <tr
                    key={teamUser.id}
                  >
                    {/*<td className="py-0" style={{ width: '22px' }}></td>*/}
                    <td><i className="fa fa-user-o" aria-hidden="true"/> {teamUser.name ?? '-'}</td>
                    <td>{teamUser.jiraUser}</td>
                    <td>{teamUser.role}</td>
                    <td>
                      <i
                        style={{ cursor: 'pointer' }}
                        className="fa fa-pencil-square-o opacity-0"
                        aria-hidden="true"
                        // onClick={() => {history.push(`/project/${c.projectId}/testCases/${c.id}`)}}
                        onClick={() => {showModal(teamUser.id)}}
                      />
                      <i
                        style={{ cursor: 'pointer' }}
                        className="fa fa-trash-o ps-2 opacity-0"
                        aria-hidden="true"
                        onClick={() => {deleteHandler(teamUser.id)}}
                      />
                    </td>
                  </tr>
                )
              }) : (<></>)}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="col pt-5">
            <NoTable params={{ title: `В этом проекте пока нет команды` }}/>
          </div>
        )}

      </div>

      <TeamForm options={{ projectId: id, userId, team, show, jiraUsers }} updateOnChange={updateOnChange}
                onClose={onClose}/>
    </>
  )
}
