import React from 'react'

export const InlineLoader = ({ size, ml = 0, mr = 0 }) => {
  if (!size) size = '40'
  return (
    <div className="spinner-border text-primary" role="status"
         style={{ width: `${size}px`, height: `${size}px`, marginLeft: `${ml}rem`, marginRight: `${mr}rem`, borderWidth: '0.1em' }}>
      <span className="visually-hidden">Загрузка...</span>
    </div>
  )
}