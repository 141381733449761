import { setBudge } from '../redux/actions/navbar.actions'
import { useDispatch } from 'react-redux'

export const useJiraSupportStats = () => {
  const dispatch = useDispatch()

  const getJiraStats = (epics) => {
    let backlog = []
    let progress = []
    let review = []
    let done = []
    let canceled = []

    if (epics && epics.length) {
      epics.forEach(t => {
        switch (t.status.name.toLowerCase()) {
          case 'к выполнению':
            backlog.push(t)
            break
          case 'в работе':
            progress.push(t)
            break
          case 'проверка':
            review.push(t)
            break
          case 'готово':
            done.push(t)
            break
          case 'отменено':
            canceled.push(t)
            break
          default:
            backlog.push(t)
        }
      })

      // here push badgets to navbar
      dispatch(setBudge('/support', epics.length.toString()))
      dispatch(setBudge('/support', epics.length.toString(), true))
      dispatch(setBudge('/support/backlog', backlog.length.toString(), true))
      dispatch(setBudge('/support/progress', progress.length.toString(), true))
      dispatch(setBudge('/support/review', review.length.toString(), true))
      dispatch(setBudge('/support/done', done.length.toString(), true))
      dispatch(setBudge('/support/canceled', canceled.length.toString(), true))
    } else {
      // here push badgets to navbar
      dispatch(setBudge('/support', ''))
      dispatch(setBudge('/support', '', true))
      dispatch(setBudge('/support/backlog', '', true))
      dispatch(setBudge('/support/progress', '', true))
      dispatch(setBudge('/support/review', '', true))
      dispatch(setBudge('/support/done', '', true))
      dispatch(setBudge('/support/canceled', '', true))
    }

    return { backlog, progress, review, done, canceled }
  }

  return { getJiraStats }
}