import React from 'react'
import {Redirect, Route, Switch} from "react-router-dom"
import { AuthPage } from './components/Pages/AuthPage'
import {MainPage} from "./components/Pages/MainPage"
import { UserProfilePage } from './components/Pages/user/UserProfilePage'
import { UserPasswordPage } from './components/Pages/user/UserPasswordPage'
import { ProjectCreatePage } from './components/Pages/project/ProjectCreatePage'
import { ProjectSettingsPage } from './components/Pages/project/ProjectSettingsPage'
import { ProjectIntegrationPage } from './components/Pages/project/ProjectIntegrationPage'
import { ProjectNotifyPage } from './components/Pages/project/ProjectNotifyPage'
import { ArchPage } from './components/Pages/architecture/ArchPage'
import { BlockPage } from './components/Pages/architecture/BlockPage'
import { TeamPage } from './components/Pages/team/TeamPage'
import { RoadmapPage } from './components/Pages/roadmap/RoadmapPage'
import { IssuesPage } from './components/Pages/support/IssuesPage'
import { SprintSettingsPage } from './components/Pages/sprint/SprintSettingsPage'
import { SprintPage } from './components/Pages/sprint/SprintPage'
import { SprintCompare } from './components/Pages/sprint/SprintCompare'

function PrivateRoute ({component: Component, authed, ...rest}) {
  return (
    <Route
      {...rest}
      render={(props) => authed === true
        ? <Component {...props} />
        : <Redirect to={{pathname: '/auth', state: {from: props.location}}} />}
    />
  )
}

export const useRoutes = isAuth => {
  return (
    <Switch>
      <PrivateRoute authed={isAuth} path="/" component={MainPage} exact />
      <PrivateRoute authed={isAuth} path="/user/profile" component={UserProfilePage} exact />
      <PrivateRoute authed={isAuth} path="/user/password" component={UserPasswordPage} exact />

      <PrivateRoute authed={isAuth} path='/project/create' component={ProjectCreatePage} exact />
      <PrivateRoute authed={isAuth} path="/settings" component={ProjectSettingsPage} exact />
      <PrivateRoute authed={isAuth} path="/settings/integration" component={ProjectIntegrationPage} exact />
      <PrivateRoute authed={isAuth} path="/settings/reports" component={ProjectNotifyPage} exact />

      <PrivateRoute authed={isAuth} path="/arch" component={ArchPage} exact />
      <PrivateRoute authed={isAuth} path="/arch/func" component={BlockPage} exact />

      <PrivateRoute authed={isAuth} path="/team" component={TeamPage} exact />

      <PrivateRoute authed={isAuth} path="/roadmap" component={RoadmapPage} exact />

      <PrivateRoute authed={isAuth} path="/support" component={IssuesPage} exact />
      <PrivateRoute authed={isAuth} path="/support/backlog" component={() => (<IssuesPage status='backlog' />)} exact />
      <PrivateRoute authed={isAuth} path="/support/progress" component={() => (<IssuesPage status='progress' />)} exact />
      <PrivateRoute authed={isAuth} path="/support/review" component={() => (<IssuesPage status='review' />)} exact />
      <PrivateRoute authed={isAuth} path="/support/done" component={() => (<IssuesPage status='done' />)} exact />
      <PrivateRoute authed={isAuth} path="/support/canceled" component={() => (<IssuesPage status='canceled' />)} exact />

      <PrivateRoute authed={isAuth} path="/sprint" component={SprintPage} exact />
      <PrivateRoute authed={isAuth} path="/sprint/curr" component={() => (<SprintPage status='Текущие' />)} exact />
      <PrivateRoute authed={isAuth} path="/sprint/planned" component={() => (<SprintPage status='Запланировано' />)} exact />
      <PrivateRoute authed={isAuth} path="/sprint/finished" component={() => (<SprintPage status='Завершено' />)} exact />
      <PrivateRoute authed={isAuth} path="/sprint/settings" component={SprintSettingsPage} exact />

      <PrivateRoute authed={isAuth} path="/sprintCompare" component={SprintCompare} exact />

      <Route path="/auth" exact>
        {isAuth ? <Redirect to="/" /> : <AuthPage />}
      </Route>

      <Redirect to="/" />
    </Switch>
  )
}