import React, { useCallback, useEffect, useState } from 'react'
import {useHttp} from "../../../hooks/http.hook"
import {useMessage} from "../../../hooks/message.hook"
import {Loader} from "../../partials/Loader"
import { useSelector } from 'react-redux'
import { InlineLoader } from '../../partials/InlineLoader'

export const ProjectNotifyPage = () => {
  const auth = useSelector(state => state.auth)
  const id = useSelector(state => state.sidebar.projectId)
  const [project, setProject] = useState(null)
  const {loading, request, error, clearError} = useHttp()
  const message = useMessage()
  const [form, setForm] = useState({
    notifyMail: 0,
    notifyTelegram: 0,
  })

  useEffect(() => {
    message(error)
    clearError()
  }, [error, message, clearError])

  const getProject = useCallback(async (token, id) => {
    try {
      if (token && id) {
        const data = await request(`/api/project/${id}`, 'GET', null, {authorization: 'Bearer ' + token})
        setProject(data.project)
        setForm({notifyMail: data.project.notifyMail, notifyTelegram: data.project.notifyTelegram})
      }
    } catch (e) {
      console.log(e)
    }
  }, [request])

  useEffect(() => {
    (async () => {
      await getProject(auth.token, id)
    })()

  }, [getProject, id, auth.token])

  const changeHandler = useCallback(async event => {
    setForm({ ...form, [event.target.name]: event.target.checked })

    // save to project
    const data = await request(`/api/project/${id}`, 'PUT', {form: {...form, [event.target.name]: event.target.checked }}, {authorization: 'Bearer ' + auth.token})
    message(data.message)

  }, [form, message, id, auth.token, request])

  if (!project) {
    return <Loader />
  }

  return (
    <>
      <div className="row mb-3">
        <div className="col-6">
        </div>

        <div className="col-4">
          <div className="row justify-content-around">
            <div className="col text-center">
              <span className="text-gray">Telegram</span>
            </div>
            <div className="col ms-2 text-center">
              <span className="text-gray">Email</span>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-6">
          <h5>Уведомления о событиях и отчётах {loading && <InlineLoader size={20} ml={1}/>}</h5>
        </div>

        <div className="col-4">
          <div className="row justify-content-around">
            <div className="col d-flex justify-content-center">
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="notifyTelegram"
                  checked={form.notifyTelegram}
                  onChange={changeHandler}
                />
              </div>
            </div>

            <div className="col ms-2 d-flex justify-content-center">
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="notifyMail"
                  checked={form.notifyMail}
                  onChange={changeHandler}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
