import React, {useCallback, useEffect, useState, useRef} from 'react'
import {useHistory} from "react-router-dom"
import {useMessage} from "../../../hooks/message.hook"
import {useHttp} from "../../../hooks/http.hook"
import {Loader} from "../../partials/Loader"
import ReactFlow, {
  Background, Controls, addEdge, ReactFlowProvider, removeElements,
} from 'react-flow-renderer'
import { Handle } from 'react-flow-renderer'
import {FlowSidebar} from "./FlowSidebar"
import { useSelector } from 'react-redux'
import { Icon } from '../../partials/Icon'
import { BlockForm } from './BlockForm'


export const ArchPage = () => {
  const id = useSelector(state => state.sidebar.projectId)
  const token = useSelector(state => state.auth.token)
  const logout = useSelector(state => state.auth.logout)
  const history = useHistory()
  const {loading, request, silentRequest, error, clearError} = useHttp()
  const message = useMessage()
  const [project, setProject] = useState(null)

  const reactFlowWrapper = useRef(null)
  const [reactFlowInstance, setReactFlowInstance] = useState(null)

  // const edgeType = 'smoothstep'
  const edgeType = 'default'
  const snapGrid = [12, 12]

  const [elements, setElements] = useState([])
  const [blockId, setBlockId] = useState(-1)
  const [show, setShow] = useState(false)


  useEffect(() => {
    document.getElementById('page-body').classList.add('nopadding')
    return () => {
      document.getElementById('page-body').classList.remove('nopadding')
    }
  }, [])

  useEffect(() => {
    message(error)
    clearError()
  }, [error, message, clearError, logout, history])

  const getProject = useCallback(async (token, id) => {
    try {
      if (token && id) {
        const data = await request(`/api/project/blocks/${id}`, 'GET', null, {authorization: 'Bearer ' + token})
        setProject(data.project)

        // before setElements do renew it (maybe some blocks changed or deleted ?)
        if (data.project && data.project.blocks.length && data.project.archStr && !elements.length) {
          let loadedEls = JSON.parse(data.project.archStr)
          // setElements(loadedEls)
          // compare each node element with real project.blocks[i]
          // console.log('loadedEls before', loadedEls)
          loadedEls = loadedEls.map(el => {
            if (el.id[0] === 'd') {
              let pBlock = data.project.blocks.find(b => b.id === el.data.b.id)
              if (pBlock) {
                el.data.b = {...pBlock}
              } else {
                if (!el.data.b.name.includes('DELETED!')) el.data.b.name = `DELETED! ${el.data.b.name}`
              }
            }
            return el
          })
          // console.log('loadedEls after', loadedEls)
          setElements(loadedEls)
        }
      }
    } catch (e) {
      console.log(e)
    }
  }, [request, elements.length])

  useEffect(() => {
    (async () => {
      await getProject(token, id)
    })()
  }, [getProject, id, token])

  // useEffect(() => {
  //   console.log('BLOCK ID: ', blockId)
  // }, [blockId])
  
  // Flow start
  const saveFlow = useCallback(async (token, id, flow) => {
    try {
      await silentRequest(`/api/project/archUpd`, 'POST', {id, archStr: JSON.stringify(flow)}, {authorization: 'Bearer ' + token})
      // const data = await request(`/api/project/archUpd`, 'POST', {id, archStr: JSON.stringify(elements)}, {authorization: 'Bearer ' + token})
      // setProject(data.project)
      // console.log('SAVING ARCH')
    } catch (e) {
      console.log(e)
    }
  }, [silentRequest])

  useEffect(() => {
    if (token && id && reactFlowInstance) {
      (async () => {
        await saveFlow(token, id, reactFlowInstance.toObject().elements)
      })()
    }
  }, [elements, id, token])

  const onConnect = useCallback(
    (params) =>
      setElements((els) =>
          addEdge({ ...params, type: edgeType }, els)
        // addEdge({ ...params, type: edgeType, style: { stroke: '#f00' } }, els)
        // addEdge({ ...params, animated: true, style: { stroke: '#f00' } }, els)
      ),
    []
  )

  const onElementsRemove = (elementsToRemove) =>
    setElements((els) => removeElements(elementsToRemove, els))

  const onLoad = (_reactFlowInstance) =>
    setReactFlowInstance(_reactFlowInstance)

  const onDragOver = (event) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
  }

  const onDragStop = (event) => {
    event.preventDefault()
    // reSet Elements, it's force save Flow to DB
    setElements(els => [...els])
  }

  const onElementClick = (event, node) => {
    event.preventDefault()
    setBlockId(node.data.b.id)
    setShow(true)
  }

  const onDrop = (event) => {
    event.preventDefault()

    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect()
    const type = event.dataTransfer.getData('application/reactflow')
    const b = JSON.parse(event.dataTransfer.getData('b')) // data from sidebar component
    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    })
    const newNode = {
      id: `dndnode_${+ new Date()}`,
      type,
      position,
      data: { label: `${type} node`, b },
    }

    setElements((es) => es.concat(newNode))

  }

  const CustomNode = ({data, id}) => {
    let b = data.b
    // console.log('Data in component', data)
    return (
      <>
        <Handle
          type="target"
          position="top"
        />

        <div className="d-flex justify-content-start" style={{borderRadius: '5px', boxShadow: '2px 2px 2px #e2e2e2', padding: '10px', backgroundColor: '#fff', border: '1px solid #e2e2e2', maxWidth: '250px', minWidth: '250px'}}>
          <div className="">
            {b.type === 'Action' ? <Icon name='flash' size='20px' mt='6px' mr='0'/> : <Icon name='box' size='20px' mt='6px' mr='0'/>}
          </div>
          <div className="d-flex flex-column w-100">
            <div className="blockin d-flex flex-column">
              <div className="blocktext">
                <p className="blocktitle" style={{color: b.name.includes('DELETED!') ? 'red' : 'black'}}>{b.name}</p>
                <p className="blockdesc">{b.description}</p>
              </div>
              <div className="d-flex justify-content-end" style={{marginTop: '5px'}}>
                <i
                  style={{cursor: 'pointer'}}
                  className="fa fa-trash-o text-gray clear-minWidth flow-icon"
                  aria-hidden="true"
                  onClick={
                    () => {
                      // console.log('clicked delete:', `Node id ${id}, Block id: ${b.id}`)
                      // delete Node and linked edges
                      setElements(prev => prev.filter(el => el.id !== id).filter(el => el.target !== id).filter(el => el.source !== id))
                      setShow(false)
                    }
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <Handle
          type="source"
          position="bottom"
        />
      </>
    )
  }
  // Flow end

  // Edit block
  const updateOnChange = async () => {
    await getProject(token, id)
    // hide sidebar
    setShow(false)
  }

  const showModal = (id = -new Date()) => {
    setBlockId(id)
    // show sidebar
    setShow(true)
  }

  const createBlock = () => {
    showModal()
  }

  const onClose = () => {
    setShow(false)
  }
  // Edit block end

  if (!project || loading) {
    return <Loader />
  }

  return (
    <>
        <div className="row h-100">
          <div className="col-12">

            <div className="dndflow">
              <ReactFlowProvider>
                <FlowSidebar project={project} createBlock={createBlock}/>
                <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                  <ReactFlow
                    elements={elements}
                    nodeTypes={{special: CustomNode}}
                    onConnect={onConnect}
                    onElementsRemove={onElementsRemove}
                    snapToGrid={true}
                    snapGrid={snapGrid}
                    onElementClick={onElementClick}
                    onLoad={onLoad}
                    onDrop={onDrop}
                    onNodeDragStop={onDragStop}
                    onDragOver={onDragOver}
                  >
                    <Background
                      variant="dots"
                      gap={12}
                      size={0.5}
                    />
                    <Controls showZoom={false} showInteractive={false} />
                  </ReactFlow>
                </div>
                <BlockForm options={{ blockId, project, show }} updateOnChange={updateOnChange} onClose={onClose}/>
              </ReactFlowProvider>
            </div>

          </div>
        </div>
    </>
  )
}
