import React, { useEffect, useState } from 'react'
import { useJira } from '../../../hooks/jira.hook'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { useMessage } from '../../../hooks/message.hook'
import { setActiveItem, setActiveSubItem, showSubMenu } from '../../../redux/actions/navbar.actions'
import { Icon } from '../../partials/Icon'
import { Loader } from '../../partials/Loader'
import { NoTable } from '../../partials/NoTable'
import { useJiraSupportStats } from '../../../hooks/jiraSupportStats.hook'
import { IssueDetails } from './IssueDetails'

export const IssuesPage = ({ status = '' }) => {
  const { getJiraEpics, jiraLoading, jiraConnected } = useJira()
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const id = useSelector(state => state.sidebar.projectId)
  const history = useHistory()
  const message = useMessage()
  const [jiraStatuses, setJiraStatuses] = useState([])
  const [jiraTasks, setJiraTasks] = useState([])
  const { getJiraStats } = useJiraSupportStats()
  const [statuses] = useState({
    'к выполнению': 'Backlog',
    'в работе': 'In progress',
    'проверка': 'In review',
    'готово': 'Done',
    'отменено': 'Cancelled',

  })
  const [title, setTitle] = useState('Все обращения')
  const [projectCount, setProjectCount] = useState(0)
  const [progressCount, setProgressCount] = useState(0)
  const [show, setShow] = useState(false)
  const [task, setTask] = useState(false)
  const [changed, setChanged] = useState(false)

  useEffect(() => {
    (async () => {
      // Get jira
      const jira = await getJiraEpics(auth, id, 'issues')

      if (jira.message) message(jira.message)

      const { backlog, progress, review, done, canceled } = getJiraStats(jira.jiraTasks)

      setProgressCount(progress.length)

      let distinctPrj = []

      switch (status) {
        case 'backlog':
          setTitle('Обращения к выполнению')
          setJiraTasks([...backlog])

          distinctPrj = [...new Set(backlog.map(t => t.parent ? t.parent.fields.summary : '-'))]
          setProjectCount(distinctPrj.length)
          // setJiraTasks(jira.jiraTasks.filter(el => el.status.name.toLowerCase() === 'к выполнению'))
          break
        case 'progress':
          setTitle('Обращения в работе')
          setJiraTasks([...progress])

          distinctPrj = [...new Set(progress.map(t => t.parent ? t.parent.fields.summary : '-'))]
          setProjectCount(distinctPrj.length)
          // setJiraTasks(jira.jiraTasks.filter(el => el.status.name.toLowerCase() === 'в работе'))
          break
        case 'review':
          setTitle('Обращения на проверке')
          setJiraTasks([...review])

          distinctPrj = [...new Set(review.map(t => t.parent ? t.parent.fields.summary : '-'))]
          setProjectCount(distinctPrj.length)
          // setJiraTasks(jira.jiraTasks.filter(el => el.status.name.toLowerCase() === 'проверка'))
          break
        case 'done':
          setTitle('Обращения готовы')
          setJiraTasks([...done])

          distinctPrj = [...new Set(done.map(t => t.parent ? t.parent.fields.summary : '-'))]
          setProjectCount(distinctPrj.length)
          // setJiraTasks(jira.jiraTasks.filter(el => el.status.name.toLowerCase() === 'готово'))
          break
        case 'canceled':
          setTitle('Обращения отклонены')
          setJiraTasks([...canceled])

          distinctPrj = [...new Set(canceled.map(t => t.parent ? t.parent.fields.summary : '-'))]
          setProjectCount(distinctPrj.length)
          // setJiraTasks(jira.jiraTasks.filter(el => el.status.name.toLowerCase() === 'отменено'))
          break
        default:
          setTitle('Все обращения')
          setJiraTasks(jira.jiraTasks)

          distinctPrj = [...new Set(jira.jiraTasks.map(t => t.parent ? t.parent.fields.summary : '-'))]
          setProjectCount(distinctPrj.length)
      }

      // setJiraTasks(jira.jiraTasks)
      setJiraStatuses(jira.jiraStatuses)

      // console.log('jiraStatuses', jira.jiraStatuses)
      // console.log('jiraTasks', jira.jiraTasks)
    })()
  }, [getJiraEpics, auth, id, status, changed])

  const sortByValue = e => {
    switch (e.target.value) {
      case 'project':
        setJiraTasks([...jiraTasks.sort((a, b) => (a.parent.fields.summary > b.parent.fields.summary) ? 1 : ((b.parent.fields.summary > a.parent.fields.summary) ? -1 : 0))])
        break
      case 'status':
        setJiraTasks([...jiraTasks.sort((a, b) => (statuses[a.status.name.toLowerCase()] > statuses[b.status.name.toLowerCase()]) ? 1 : ((statuses[b.status.name.toLowerCase()] > statuses[a.status.name.toLowerCase()]) ? -1 : 0))])
        break
      case 'date':
        setJiraTasks([...jiraTasks.sort((a, b) => (a.fields.created > b.fields.created) ? 1 : ((b.fields.created > a.fields.created) ? -1 : 0))])
        break
      default:
        setJiraTasks([...jiraTasks.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0)).reverse()])
    }
  }

  const showModal = (tsk) => {
    setTask(tsk)
    // show sidebar
    setShow(true)
  }

  const onClose = (changed) => {
    setShow(false)
    setChanged(changed)
  }

  if (jiraLoading) {
    return <Loader/>
  }

  return (
    <>
      {!jiraLoading && !jiraConnected ?
        <div className="row justify-content-center">
          <div className="col-12">
            <div style={{
              backgroundColor: '#fff4cd',
              color: '#866607',
              padding: '10px',
              textAlign: 'justify',
              marginBottom: '3rem'
            }}>
              <span style={{ fontWeight: 600 }}>Важно!</span> У вас не настроена интеграция с Jira.
              Вы можете настроить интеграцию <Link to={`#`} onClick={() => {
              dispatch(setActiveItem('/settings'))
              dispatch(setActiveSubItem('/settings/integration'))
              dispatch(showSubMenu())
              history.push(`/settings/integration`)
            }}>здесь.</Link>
            </div>
          </div>
        </div>
        : <>
          <div className="row justify-content-between mb-3">
            <div className="col-auto">

              <h5 className="mb-0">{title}</h5>
              <p className=""
                 style={{ marginBottom: '7px' }}>{`${projectCount} проектов · ${jiraTasks.length} обращений · ${status === 'progress' || !status ? progressCount : 0} в работе`}</p>
            </div>

            <div className="col-auto align-self-end text-end">
              <div className="row">
                <label htmlFor="inputEmail3" className="col-auto col-form-label">Сортировка</label>
                <div className="col-auto">
                  <select
                    id="sortBy"
                    name="sortBy"
                    className="form-select"
                    onChange={sortByValue}
                  >
                    <option value="id">Id</option>
                    <option value="project">Проект</option>
                    <option value="status">Статус</option>
                    <option value="date">Дата</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-3 h-100">

            {!jiraLoading && jiraTasks && jiraTasks.length ? (
              <div className="col-12">
                <table className="table">
                  <thead>
                  <tr style={{ fontSize: '16px' }}>
                    <th className="text-gray">id</th>
                    <th className="text-gray">Название и описание</th>
                    <th className="text-gray">Проект</th>
                    <th className="text-gray">Статус</th>
                    <th className="text-gray">Дата открытия</th>
                  </tr>
                  </thead>
                  <tbody className="table-hover">
                  {jiraTasks.map(task => {
                    return (
                      <tr
                        key={task.id}
                        onClick={() => {showModal(task)}}
                        style={{cursor: 'pointer'}}
                      >
                        <td>
                          {task.id}
                        </td>
                        <td style={{ maxWidth: '300px' }}>
                          <p className="mb-0" style={{ maxWidth: '430px' }}>{task.summary}</p>
                          <p className="mb-0 text-gray"
                             style={{
                               fontSize: '10px',
                               maxWidth: '430px'
                             }}>{task.description ? task.description.content.map(c1 => c1.content.map(c2 => c2.text).join(' ')).join('\n') : ''}</p>
                        </td>
                        <td>
                          <Icon name="Tag" size="16px"/>{task.parent ? task.parent.fields.summary : '-'}
                        </td>
                        <td>
                          {statuses[task.status.name.toLowerCase()] || ''}
                        </td>
                        <td>
                          {new Date(task.fields.created).toLocaleDateString('ru-RU', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric'
                          })}
                        </td>
                      </tr>
                    )
                  })}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="col pt-5">
                {jiraLoading ? <Loader size={80}/> : <NoTable params={{ title: `-------` }}/>}
              </div>
            )}
          </div>

          <IssueDetails options={{ task, show, statuses }} onClose={onClose} />
        </>
      }
    </>
  )
}
